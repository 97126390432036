import { ILineItem, IQuoteDocument } from "../components/books/LineItem";
import { getNumber } from "../components/books/QuotesColumnConfig";
import {
  APPROVAL_STATUS_DISPLAY_VALUE,
  BOOKS_CUSTOM_FIELD_TYPE,
  COUNTRY_CODES,
  CURRENCY_PRECISION,
  CUST_EXPORT_WO_PAY,
  CUST_EXPORT_W_PAY,
  CUST_NA,
  CUST_SEZ_WO_PAY,
  CUST_SEZ_W_PAY,
  DEFAULT_PRODUCT_UOM,
  DOC_TYPE,
  LABELS,
  RECURRING_DOCUMENT_TYPE,
  OPTIONAL_VALUES,
  REGISTERED_BUSINESS_REGULAR,
  SPECIAL_ECONOMIC_ZONE
} from "../constants/Constant";
import { CURRENCY_SYMBOLS } from "../constants/Currencies";
import {
  APPROVAL_STATUS,
  APP_NAME,
  FILTER_OPERATORS,
  GST_TYPE,
  QUOTE_DOC_KEYS
} from "../constants/Enum";
import { PAGE_ROUTES } from "../managers/RouteManager";
import { COLUMN_CODE, TableManger, TABLES } from "../managers/TableManger";
import TenantManager from "../managers/TenantManager";
import UserManager from "../managers/UserManager";
import { IContactAddress } from "../model/BooksContact";
import {
  IApprovalCondition,
  IApprovalDetails,
  IDocumentApprovalNode,
  IQuotation,
  IQuotationItem
} from "../model/Quotation";
import { store } from "../redux/store";
import BooksService from "../services/books";
import { DateUtil, DATE_FORMATS } from "../utility/Date";
import { roundOffToTenantDecimalScale } from "../utility/Math";
import Utility, {
  ensureArray,
  getCapitalized,
  isObject,
  isString
} from "../utility/Utility";
import { INPUT_TYPE } from "deskera-ui-library";

export const COUNTRIES_ALLOWED_FOR_SALES_ORDER = [
  COUNTRY_CODES.IN,
  COUNTRY_CODES.US,
  COUNTRY_CODES.SG,
  COUNTRY_CODES.MY
];
export class BooksHelper {
  static prepareQuotationPayload(
    document: IQuoteDocument,
    contact: any,
    address?: { billing: IContactAddress; shipping: IContactAddress }
  ): IQuotation {
    let shippingAddress =
      address?.shipping ||
      contact.shippingAddress?.find((contact) => contact.preferred) ||
      contact.shippingAddress?.[0];

    /* In case of US tax system, if org is not compliance enabled, then not sending incorrect shipping address to avoid tax calculations */
    // if (
    //   BooksService.getTenantCountry() === "US" &&
    //   !TenantManager.isComplianceEnabled() &&
    //   shippingAddress &&
    //   (!shippingAddress.postalCode ||
    //     !shippingAddress.address1 ||
    //     !shippingAddress.state)
    // ) {
    //   shippingAddress = null;
    // }

    let additionalCharges = document.additionalCharges;
    if (
      !Utility.isEmptyObject(additionalCharges) &&
      Utility.isEmptyObject(additionalCharges.globalDiscount)
    ) {
      additionalCharges = {
        ...additionalCharges,
        globalDiscount: {
          isSubTotalOnly:
            false /* Enabling only post-tax discount option as in BOOKS */,
          isPercent: false,
          percent: 0,
          amount: 0,
          hasError: false
        }
      };
    }

    const payload: IQuotation = {
      id: document.id,
      contactCode: contact?.code,
      contact,
      sequenceFormat: document.sequenceFormat,
      documentSequenceCode: document?.documentSequenceCode,
      validTillDate: DateUtil.getFormattedDateString(
        document.dueDate,
        DateUtil.getOrgDateFormat(),
        DATE_FORMATS["DD-MM-YYYY"]
      ),
      documentDate: DateUtil.getFormattedDateString(
        document.quoteDate,
        DateUtil.getOrgDateFormat(),
        DATE_FORMATS["DD-MM-YYYY"]
      ),
      documentType: DOC_TYPE.QUOTE,
      shipByDate: DateUtil.getFormattedDateString(
        document.shipByDate,
        DateUtil.getOrgDateFormat(),
        DATE_FORMATS["DD-MM-YYYY"]
      ),
      memo: document.memo,
      billTo:
        address?.billing ||
        contact.billingAddress?.find((contact) => contact.preferred) ||
        contact.billingAddress?.[0],
      shipTo: shippingAddress,
      totalAmount: document.total,
      currency: document.currency ?? UserManager.getUserCurrency(),
      currencyCode: document.currencyCode ?? UserManager.getUserCurrency(),
      status: "OPEN",
      fulfillmentStatus: "UNFULFILLED",
      recurring: false,
      exchangeRate: document.exchangeRate ?? 1,
      additionalCharges: additionalCharges,
      sourceAppName: APP_NAME.CRM_3,
      quotationItemDtoList: document.items.map((item) =>
        BooksHelper.convertLineItemToQuotationDto(item, contact, document)
      ),
      approvalStatus: document.approvalStatus,
      customField: document.customField,
      gstTreatment: contact?.gstTreatment,
      gstin: contact?.gstin,
      vendorType: contact?.vendorType,
      customerType: contact?.customerType,
      gstType: document?.gstType || 1,
      crm3DealId: document?.crm3DealId ?? "",
      crm3DealOwnerIds: ensureArray(document?.crm3DealOwnerIds?._id),
      priceListId: document?.priceListId ?? 0,
      priceListName: document?.priceListName ?? "",
      primary: document?.primary,
      locked: document?.locked,
      multiApprovalDetails: document?.multiApprovalDetails,
      editingBy: 0,
      editingAt: null,
      editingLocked: false,
      attachmentIds: document?.attachmentIds ?? [],
      attachments: document?.attachments ?? [],
      seqCodeAlreadyDumped: document?.seqCodeAlreadyDumped ?? false
    };
    return payload;
  }
  static convertLineItemToQuotationDto(
    lineItem: ILineItem,
    contact,
    document?: any
  ): IQuotationItem {
    return {
      ...lineItem,
      productCode: lineItem.product?.productId,
      taxCode: lineItem.tax?.code,
      unitPrice: lineItem.unitPrice || 0,
      totalAmount: lineItem.totalAmount,
      productQuantity: lineItem.productQuantity,
      discount: lineItem.discount,
      taxAmount: lineItem.taxAmount,
      discountInPercent: lineItem.isDiscountInPercentage,
      uomQuantity: lineItem.productQuantity,
      documentUom: lineItem?.uom?.id || DEFAULT_PRODUCT_UOM,
      uom: lineItem?.uom,
      productDescription: lineItem.productDescription,
      lineNumber: lineItem.lineNumber,
      product: lineItem.product,
      hsnOrSacCode: lineItem?.product?.hsnOrSacCode,
      gstType: document?.gstType ? document?.gstType : lineItem.gstType || 1,
      taxPreference: lineItem?.product?.taxPreference,
      taxExemptionReason: lineItem?.product?.taxExemptionReason,
      pendingQtyToConvert: lineItem.productQuantity,
      cessAmount: lineItem.cessAmount || 0,
      cgstAmount: lineItem.cgstAmount || 0,
      cessRule: lineItem.cessRule || 0,
      cgstRate: lineItem.cgstRate || 0,
      igstAmount: lineItem.igstAmount || 0,
      igstRate: lineItem.igstRate || 0,
      rcmRate: lineItem.rcmRate || 0,
      sgstAmount: lineItem.sgstAmount || 0,
      sgstRate: lineItem.sgstRate || 0,
      previousPrice: lineItem.basePrice,
      optional:
        lineItem.optional?.id === 1 || !lineItem.optional ? false : true,
      productGroupUuid: lineItem.productGroupUuid,
      pendingQuantity: lineItem.productQuantity
    };
  }
  static getQuoteDocumentFromPayload(payload: IQuotation): IQuoteDocument {
    let additionalCharges = payload.additionalCharges;

    if (
      !Utility.isEmptyObject(additionalCharges?.globalDiscount) &&
      additionalCharges.globalDiscount.amount == 0
    ) {
      additionalCharges = {
        ...additionalCharges,
        globalDiscount: null
      };
    } else if (
      !Utility.isEmptyObject(additionalCharges?.globalDiscount) &&
      !isNaN(additionalCharges.globalDiscount.amount)
    ) {
      additionalCharges = {
        ...additionalCharges,
        globalDiscount: {
          ...additionalCharges.globalDiscount,
          amount: roundOffToTenantDecimalScale(
            additionalCharges.globalDiscount.amount
          )
        }
      };
    }

    return {
      id: payload.id,
      contactCode: payload.contactCode,
      contact:
        payload.contactDto?.id || payload.contactDto?.code
          ? payload.contactDto
          : {},
      memo: payload.memo,
      shipByDate: payload.shipByDate
        ? DateUtil.getFormattedDateString(
            payload.shipByDate,
            DATE_FORMATS["DD-MM-YYYY"],
            DateUtil.getOrgDateFormat()
          )
        : new Date(),
      dueDate: payload.validTillDate
        ? DateUtil.getFormattedDateString(
            payload.validTillDate,
            DATE_FORMATS["DD-MM-YYYY"],
            DateUtil.getOrgDateFormat()
          )
        : new Date(),
      quoteDate: payload.documentDate
        ? DateUtil.getFormattedDateString(
            payload.documentDate,
            DATE_FORMATS["DD-MM-YYYY"],
            DateUtil.getOrgDateFormat()
          )
        : new Date(),
      total: payload.totalAmount,
      additionalCharges: additionalCharges,
      currency: payload.currency,
      items: !Utility.isEmptyObject(payload.quotationItemDtoList)
        ? payload.quotationItemDtoList.map(
            BooksHelper.convertQuotationDtoToLineItem
          )
        : [],
      documentSequenceCode: payload.documentSequenceCode,
      sequenceFormat: payload.sequenceFormat,
      approvalStatus: payload.approvalStatus,
      customField: payload.customField,
      productGroupId: payload.productGroupId ?? 0,
      productGroupName: payload.productGroupName ?? "",
      productGroupUuid: payload.productGroupUuid ?? "",
      crm3DealId: payload.crm3DealId ?? "",
      primary: payload?.primary ?? false,
      exchangeRate: payload?.exchangeRate ?? 1,
      priceListId: payload?.priceListId ?? 0,
      priceListName: payload?.priceListName ?? "",
      multiApprovalDetails: payload?.multiApprovalDetails,
      locked: payload?.locked,
      editingLocked: payload.editingLocked,
      editingBy: payload.editingBy,
      editingAt: payload.editingAt,
      attachmentIds: payload?.attachmentIds,
      attachments: payload?.attachments,
      seqCodeAlreadyDumped: payload.seqCodeAlreadyDumped ?? false
    };
  }
  static convertQuotationDtoToLineItem(quotation: IQuotationItem): ILineItem {
    const isDiscountInPercentage =
      quotation[QUOTE_DOC_KEYS.DISCOUNT_IN_PERCENT] ??
      quotation.discountInPercent;
    return {
      ...quotation,
      discount: quotation.discount,
      isDiscountInPercentage: isDiscountInPercentage,
      unitPrice: quotation.unitPrice,
      unitDiscount: isDiscountInPercentage
        ? quotation.discount
        : Utility.roundingOff(quotation.discount / quotation.productQuantity),
      basePrice: quotation?.previousPrice || quotation.unitPrice,
      taxCode: quotation.taxCode,
      totalAmount: quotation.totalAmount,
      productQuantity: quotation.productQuantity,
      product: quotation.product,
      tax: quotation.tax,
      taxAmount: quotation.taxAmount,
      lineNumber: quotation.lineNumber,
      uomQuantity: quotation.uomQuantity,
      uomUnitPrice: quotation.uomUnitPrice,
      documentUom: quotation.documentUom,
      documentUOMSchemaDefinition: quotation?.documentUOMSchemaDefinition,
      optional: quotation.optional ? OPTIONAL_VALUES[1] : OPTIONAL_VALUES[0],
      gstType: quotation.gstType,
      productDescription: quotation.productDescription
    };
  }

  /* ***************** DRAFT DOCUMENTS ****************** */
  static getDraftColumnId(columnCode: string) {
    return TableManger.getColumnId(TABLES.DRAFT, columnCode);
  }

  static getDraftDocumentPayload(
    record: IQuotation,
    draftType: string,
    needPayloadForUpdate = false
  ) {
    /* if (record.documentSequenceCode) {
      delete record["documentSequenceCode"];
    } */

    let payloadData = {
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.NAME)]:
        `${draftType}-Draft`,
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.TYPE)]: draftType,
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.IS_SAVED)]: true,
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.IS_MAXIMIZED)]: true, // set this to true as we don't want to save the draft in minimized state
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.IS_CENTER_ALIGNED)]:
        true, // set this to true as we don't want to save the draft in minimized state
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.PAYLOAD)]:
        Utility.encodeJSON(record),
      [BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.APP_NAME)]:
        APP_NAME.CRM_3
    };

    return needPayloadForUpdate ? payloadData : { cells: payloadData };
  }

  static async saveAsDraft(
    payload: any,
    draftData: {
      draftType: string;
      draftId?: string;
      isApprovalRequired?: Boolean;
    }
  ) {
    const { draftType, draftId, isApprovalRequired } = draftData;

    if (isApprovalRequired) {
      payload.approvalStatus = APPROVAL_STATUS["PENDING_FOR_APPROVAL"];
      payload.multiApprovalDetails = {
        ...(payload.multiApprovalDetails || {}),
        currentLevel: payload?.multiApprovalDetails?.currentLevel || 1,
        approvalHistory: payload?.multiApprovalDetails?.approvalHistory || [],
        approvalRequiredFor:
          payload?.multiApprovalDetails?.approvalRequiredFor ||
          UserManager.getUserEmail()
      };
    } else {
      payload.approvalStatus =
        payload.approvalStatus || APPROVAL_STATUS["NOT_REQUIRED"];
    }

    if (draftId) {
      const dataToSave = BooksHelper.getDraftDocumentPayload(
        payload,
        draftType,
        true
      );
      return BooksService.updateDraftRecord(dataToSave, draftId);
    } else {
      payload.createdUserName = UserManager.getUserName();
      payload.createdUserEmail = UserManager.getUserEmail();

      const dataToSave = BooksHelper.getDraftDocumentPayload(
        payload,
        draftType
      );
      return BooksService.createDraftRecord(dataToSave);
    }
  }

  static parseDraftRecordsToDisplay(
    recordList: any[],
    documentType = DOC_TYPE.QUOTE,
    search: string = ""
  ) {
    let draftQuotes = [];
    const payloadKey = BooksHelper.getDraftColumnId(COLUMN_CODE.DRAFTS.PAYLOAD);

    recordList?.forEach((data) => {
      if (Utility.isEmptyObject(data.cells[payloadKey])) return;

      let draftQuoteJson = isString(data.cells[payloadKey])
        ? Utility.decodeJSON(data.cells[payloadKey])
        : data.cells[payloadKey];

      const draftQuote = {
        ...draftQuoteJson,
        draftId: data._id,
        draftCode: data.cells.documentSequenceCode,
        isDraftDocument: true,
        createdBy: data.cells?.createdBy
      };

      if (!draftQuote.documentType) {
        draftQuote.documentType = documentType;
      }

      draftQuote.contactDto = draftQuote.contact;
      draftQuotes.push(draftQuote);
    });

    if (!Utility.isEmptyObject(search)) {
      draftQuotes = draftQuotes.filter(
        (draft) =>
          draft.draftCode?.toLowerCase()?.search(search?.toLowerCase(), "i") >=
            0 ||
          draft?.contactDto?.name
            ?.toLowerCase()
            ?.search(search?.toLowerCase(), "i") >= 0
      );
    }

    return draftQuotes.filter(
      (draft) => draft.approvalStatus !== APPROVAL_STATUS.APPROVED
    );
  }

  /* ***************** DOC APPROVAL FLOW **************** */
  static getApprovalFlowsByDocType(documentType: DOC_TYPE) {
    let flowNodes = store.getState().books.approvalFlows;

    flowNodes = Utility.isEmptyObject(flowNodes)
      ? []
      : flowNodes.filter(
          (approvalNode) => approvalNode.fields[0].field_type === documentType
        );

    return flowNodes;
  }

  static isApprovalFlowsPresent(documentType: DOC_TYPE = DOC_TYPE.QUOTE) {
    const flowNodes = BooksHelper.getApprovalFlowsByDocType(documentType);
    return !Utility.isEmptyObject(flowNodes);
  }

  static getApprovalFlowsForLoggedInUser(
    documentType: DOC_TYPE,
    requiredFor?: string
  ) {
    if (!requiredFor) {
      requiredFor = UserManager.getUserEmail();
    }

    const flowNodes = BooksHelper.getApprovalFlowsByDocType(documentType);

    const approvalFlowsForCurrentUser = flowNodes.filter((approvalNode) =>
      approvalNode.fields[0].approvalFor.split(",").includes(requiredFor)
    );

    return approvalFlowsForCurrentUser;
  }

  static getApproverFlowsForLoggedInUser(
    documentApprovalDetails: any,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE,
    approver = UserManager.getUserEmail()
  ) {
    const flowNodes = BooksHelper.getApprovalFlowsByDocType(documentType);

    const approverFlowsForCurrentUser = flowNodes.filter((flowNodeData) => {
      const approvalDetails = flowNodeData.fields[0];
      let isApproverPresent: boolean;

      /* If current document creator is not listed as submitter in current automation workflow */
      if (
        documentApprovalDetails?.approvalRequiredFor &&
        approvalDetails.approvalFor &&
        !approvalDetails.approvalFor.includes(
          documentApprovalDetails.approvalRequiredFor
        )
      ) {
        return false;
      }

      if (Utility.isEmptyObject(approvalDetails.approver)) {
        isApproverPresent = !Utility.isEmptyObject(
          approvalDetails.multiApprovalDetails?.filter(
            (approvalLevelDetail) =>
              approvalLevelDetail.level ===
                (documentApprovalDetails?.currentLevel || 1) &&
              approvalLevelDetail.approver?.includes(approver)
          )
        );
      } else {
        isApproverPresent = approvalDetails.approver.includes(approver);
      }

      return isApproverPresent;
    });

    return approverFlowsForCurrentUser;
  }

  static isApprovalRequired(
    payload: IQuotation,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE,
    requiredFor?: string
  ) {
    const approvalFlowsForCurrentUser =
      BooksHelper.getApprovalFlowsForLoggedInUser(documentType, requiredFor);

    if (Utility.isEmptyObject(approvalFlowsForCurrentUser)) {
      return false;
    }

    return approvalFlowsForCurrentUser.some((approvalFlow) =>
      BooksHelper.documentMatchesApprovalNodeConditions(
        approvalFlow.fields?.[0],
        payload,
        documentType
      )
    );
  }

  static documentMatchesApprovalNodeConditions(
    approvalDetails: IApprovalDetails,
    payload: IQuotation,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE
  ) {
    /**
     * If no conditions specified in approvalDetails,
     * then all documents will require approval
     */
    if (Utility.isEmptyObject(approvalDetails?.conditions)) {
      return true;
    }

    if (approvalDetails.logicalOperator === "and") {
      return approvalDetails.conditions.every((condition) =>
        BooksHelper.checkApprovalCondition(
          condition,
          BooksHelper.getDocumentValueByApprovalCondition(
            condition,
            payload,
            documentType
          )
        )
      );
    } else {
      return approvalDetails.conditions.some((condition) =>
        BooksHelper.checkApprovalCondition(
          condition,
          BooksHelper.getDocumentValueByApprovalCondition(
            condition,
            payload,
            documentType
          )
        )
      );
    }
  }

  static checkApprovalCondition(
    approvalCondition: IApprovalCondition,
    invoiceValue: any
  ) {
    let flag: any = false;
    const { opr: conditionOperator, value: conditionValue } = approvalCondition;

    switch (conditionOperator) {
      case FILTER_OPERATORS.EQUAL:
        if (
          typeof invoiceValue === "string" &&
          typeof conditionValue === "string" &&
          invoiceValue.toLowerCase() === conditionValue.toLowerCase()
        ) {
          flag = true;
        } else if (invoiceValue?.toString() === conditionValue?.toString()) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      case FILTER_OPERATORS.CONTAINS:
        if (
          invoiceValue
            ?.toString()
            .toLowerCase()
            .includes(conditionValue?.toString().toLowerCase())
        ) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      case FILTER_OPERATORS.GREATER_THAN:
        if (invoiceValue > conditionValue) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      case FILTER_OPERATORS.LESS_THAN:
        if (invoiceValue < conditionValue) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      case FILTER_OPERATORS.GREATER_EQUAL:
        let invDate = invoiceValue
          ? DateUtil.getDateFromStr(invoiceValue, DATE_FORMATS["DD-MM-YYYY"])
          : invoiceValue;
        let conDate = conditionValue
          ? DateUtil.getDateFromStr(conditionValue, DATE_FORMATS["DD-MM-YYYY"])
          : conditionValue;

        if (invDate && conDate && invDate.getTime() >= conDate.getTime()) {
          flag = true;
        } else {
          flag = invDate === conDate;
        }
        break;
    }
    return flag;
  }

  static getDocumentValueByApprovalCondition(
    condition: IApprovalCondition,
    payload: IQuotation,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE
  ) {
    const quoteCFList =
      store.getState()?.books?.quoteCustomFields?.content ?? [];
    if (condition.code === COLUMN_CODE.BOOKS_QUOTE.CONTACT) {
      return payload[condition.code].name;
    } else if (condition.code === COLUMN_CODE.BOOKS_QUOTE.TOTAL_AMOUNT) {
      let itemName = "quotationItemDtoList";

      if (documentType === DOC_TYPE.INVOICE) {
        itemName = "salesInvoiceItems";
      }

      let sum =
        payload &&
        payload[itemName]
          ?.map((item: any) => item.totalAmount)
          ?.reduce((prev: any, curr: any) => prev + curr, 0);
      return payload.totalAmount ?? sum;
    } else if (condition.code?.includes("custom_D")) {
      let customFields = payload?.customField ?? [];
      let matchValue =
        customFields?.find(
          (field) => "custom_" + field.code + "_" + field.id === condition?.code
        ) ?? "";

      if (Utility.isEmptyObject(matchValue?.fieldType)) {
        let quoteFilterCFFieldType = quoteCFList?.find(
          (cfquote) => cfquote.code === matchValue.code
        )?.fieldType;
        if (quoteFilterCFFieldType) {
          matchValue.fieldType = quoteFilterCFFieldType;
        }
      }

      let cfValue = matchValue.value;

      if (matchValue?.fieldType === BOOKS_CUSTOM_FIELD_TYPE.DATE) {
        cfValue = DateUtil.getDateStrFromDate(
          cfValue,
          DATE_FORMATS["DD-MM-YYYY"]
        );
      }
      if (matchValue?.fieldType === BOOKS_CUSTOM_FIELD_TYPE.USER) {
        let userCFField = quoteCFList?.find(
          (user) => user.id === matchValue?.id
        );
        cfValue =
          userCFField.attributes?.find(
            (user) => user.id?.toString() === cfValue
          )?.value ?? "";
      }

      return cfValue === condition.value ? condition.value : "";
    } else {
      return payload[condition.code];
    }
  }

  static getApproverEmail(
    payload: any,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE
  ) {
    let email = new Map();

    let allApprovalFlows = BooksHelper.getApprovalFlowsByDocType(documentType);

    let loginUserEmail =
      payload?.multiApprovalDetails?.approvalRequiredFor ||
      UserManager.getUserEmail();

    if (Utility.isEmptyObject(allApprovalFlows)) {
      return email;
    }

    let currentApprovalLevel = payload?.multiApprovalDetails?.currentLevel || 1;

    let userSpecificList =
      !Utility.isEmptyObject(allApprovalFlows) &&
      allApprovalFlows.filter((ele: any) =>
        ele.fields[0].approvalFor.split(",").includes(loginUserEmail)
      );
    if (Utility.isEmptyObject(userSpecificList)) {
      return email;
    }

    let filteredListWoConditions = userSpecificList.filter(
      (ele: any) =>
        ele.fields[0] &&
        (Utility.isEmptyObject(ele.fields[0].conditions) ||
          ele.fields[0].conditions.length === 0)
    );
    filteredListWoConditions &&
      filteredListWoConditions.length > 0 &&
      filteredListWoConditions.forEach((field: any) => {
        email.set(
          field["wf_graph_id"].toString(),
          Utility.isEmptyObject(field.fields[0].approver)
            ? field.fields[0].multiApprovalDetails
                .filter((ele: any) => ele.level === currentApprovalLevel)[0]
                .approver?.toString()
            : field.fields[0].approver
        );
      });

    let filteredList = userSpecificList.filter(
      (ele: any) => ele.fields[0] && ele.fields[0].conditions.length > 0
    );

    filteredList &&
      filteredList.length > 0 &&
      filteredList.forEach((approvalFlowNode: any) => {
        const approvalDetails = approvalFlowNode.fields[0];
        if (approvalDetails.logicalOperator === "and") {
          let isDocumentMatchingApprovalConditions =
            approvalDetails.conditions.every((condition: any) => {
              return BooksHelper.checkApprovalCondition(
                condition,
                BooksHelper.getDocumentValueByApprovalCondition(
                  condition,
                  payload,
                  documentType
                )
              );
            });
          if (isDocumentMatchingApprovalConditions) {
            email.set(
              approvalFlowNode.wf_graph_id.toString(),
              Utility.isEmptyObject(approvalDetails.approver)
                ? approvalDetails.multiApprovalDetails
                    .filter((ele: any) => ele.level === currentApprovalLevel)[0]
                    .approver?.toString()
                : approvalDetails.approver
            );
          }
        } else {
          let isDocumentMatchingApprovalConditions =
            approvalDetails.conditions.some((condition: any) => {
              return BooksHelper.checkApprovalCondition(
                condition,
                BooksHelper.getDocumentValueByApprovalCondition(
                  condition,
                  payload,
                  documentType
                )
              );
            });
          if (isDocumentMatchingApprovalConditions) {
            email.set(
              approvalFlowNode.wf_graph_id.toString(),
              Utility.isEmptyObject(approvalDetails.approver)
                ? approvalDetails.multiApprovalDetails
                    .filter((ele: any) => ele.level === currentApprovalLevel)[0]
                    .approver?.toString()
                : approvalDetails.approver
            );
          }
        }
      });

    return email;
  }

  static isApprovalRequiredByCurrentUser(
    payload: any,
    approvalType: APPROVAL_STATUS.APPROVED | APPROVAL_STATUS.REJECTED,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE
  ) {
    const loggedInUserEmail = UserManager.getUserEmail();

    /* Submitter can not be an approver */
    if (
      payload.multiApprovalDetails?.approvalRequiredFor === loggedInUserEmail
    ) {
      return false;
    }

    const approverFlows = BooksHelper.getApproverFlowsForLoggedInUser(
      payload.multiApprovalDetails,
      documentType
    )?.filter((approverFlow) =>
      BooksHelper.documentMatchesApprovalNodeConditions(
        approverFlow.fields?.[0],
        payload,
        documentType
      )
    );
    const approverFlow = approverFlows?.[0];

    let flag = false;

    if (Utility.isEmptyObject(approverFlow)) {
      return flag;
    }

    const currentLevel = payload.multiApprovalDetails?.currentLevel || 1;

    if (approverFlow.fields[0]?.multiApprovalDetails) {
      let currentApproverLevel =
        approverFlow.fields[0].multiApprovalDetails.find(
          (approverLevel) => approverLevel.level === currentLevel
        );

      /* Current user can be an approver in current level or for next level if current level is completed */
      flag = currentApproverLevel
        ? currentApproverLevel?.approver?.includes(loggedInUserEmail)
        : false;

      if (
        !flag &&
        !Utility.isEmptyObject(currentApproverLevel) &&
        !Utility.isEmptyObject(payload.multiApprovalDetails?.approvalHistory)
      ) {
        let allUsersFromCurrentLevelApproved = false;
        const alreadyApprovedBy = currentApproverLevel.approver.filter(
          (approverEmail) => {
            return Boolean(
              payload.multiApprovalDetails.approverUsers?.[
                currentLevel
              ]?.includes(approverEmail)
            );
          }
        );
        if (
          currentApproverLevel.condition === "ANY" &&
          alreadyApprovedBy.length > 0
        ) {
          allUsersFromCurrentLevelApproved = true;
        } else if (
          currentApproverLevel.condition === "ALL" &&
          alreadyApprovedBy.length === currentApproverLevel.approver.length
        ) {
          allUsersFromCurrentLevelApproved = true;
        }

        if (allUsersFromCurrentLevelApproved) {
          currentApproverLevel =
            approverFlow.fields[0]?.multiApprovalDetails.find(
              (approverLevel) => approverLevel.level === currentLevel + 1
            );
        }
        /* Is current user present in next level as approver */
        flag = currentApproverLevel
          ? currentApproverLevel?.approver?.includes(loggedInUserEmail)
          : false;
      }
    }

    if (
      approvalType === APPROVAL_STATUS.APPROVED &&
      flag &&
      !Utility.isEmptyObject(payload?.multiApprovalDetails?.approverUsers)
    ) {
      flag =
        !payload.multiApprovalDetails.approverUsers[
          payload.multiApprovalDetails.currentLevel || 1
        ]?.includes(loggedInUserEmail);
    } else if (
      approvalType === APPROVAL_STATUS.REJECTED &&
      flag &&
      !Utility.isEmptyObject(payload?.multiApprovalDetails?.rejectedUsers)
    ) {
      flag =
        !payload.multiApprovalDetails.rejectedUsers[
          payload.multiApprovalDetails.currentLevel
        ]?.includes(loggedInUserEmail) &&
        !payload?.multiApprovalDetails?.approverUsers?.[
          payload?.multiApprovalDetails?.currentLevel
        ]?.includes(loggedInUserEmail);
    }

    return flag;
  }

  static getApprovalStatusDisplayValue(
    doc: any,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE
  ) {
    let approvalStatus = doc.approvalStatus;
    let displayStatus =
      APPROVAL_STATUS_DISPLAY_VALUE[
        approvalStatus || APPROVAL_STATUS.NOT_REQUIRED
      ];

    if (
      doc.isDraftDocument &&
      approvalStatus === APPROVAL_STATUS.NOT_REQUIRED
    ) {
      return "-";
    }

    if (
      !approvalStatus ||
      [
        APPROVAL_STATUS.APPROVED,
        APPROVAL_STATUS.NOT_REQUIRED,
        APPROVAL_STATUS.EDITING
      ].includes(approvalStatus)
    ) {
      return displayStatus;
    }

    let currentApproverCondition: IDocumentApprovalNode | null = null;

    const requiredFor = doc?.multiApprovalDetails?.approvalRequiredFor || "";
    let approvalFlowNodes = BooksHelper.getApprovalFlowsForLoggedInUser(
      documentType,
      requiredFor
    );

    if (!Utility.isEmptyObject(approvalFlowNodes)) {
      currentApproverCondition = approvalFlowNodes.find((approvalFlowNode) =>
        BooksHelper.documentMatchesApprovalNodeConditions(
          approvalFlowNode.fields[0],
          doc,
          documentType
        )
      );
    }

    let currentLevelNode =
      currentApproverCondition?.fields?.[0]?.multiApprovalDetails?.filter(
        (approverLevelDetail) =>
          approverLevelDetail.level === doc?.multiApprovalDetails?.currentLevel
      );

    if (Utility.isEmptyObject(currentLevelNode)) {
      displayStatus += ` at level ${
        Utility.isEmptyObject(doc?.multiApprovalDetails?.approvalHistory)
          ? 1
          : doc?.multiApprovalDetails?.currentLevel
      }`;
    } else {
      displayStatus += ` at ` + currentLevelNode[0].levelLabel;
    }

    return displayStatus;
  }

  static checkMultiApprovalRequired(draft: any, status: APPROVAL_STATUS) {
    let multiApproveRequired = status === APPROVAL_STATUS.REJECTED;
    let condition: "ANY" | "ALL" = "ANY";
    let loggedInUserEmail = UserManager.getUserEmail();
    let approverFlows = BooksHelper.getApproverFlowsForLoggedInUser(
      draft.multiApprovalDetails
    )?.filter((approverFlow) =>
      BooksHelper.documentMatchesApprovalNodeConditions(
        approverFlow.fields?.[0],
        draft
      )
    );
    const approvalFlowNode = approverFlows?.[0];

    let currentLevel = draft.multiApprovalDetails?.currentLevel || 1;

    let multiApprovalHistory = {
      currentLevel: currentLevel,
      approvalHistory: [
        ...(draft.multiApprovalDetails?.approvalHistory || []),
        {
          level: currentLevel,
          approvedBy: loggedInUserEmail,
          approvedDate: DateUtil.getDateStrFromDate(
            new Date(),
            DATE_FORMATS["DD-MM-YYYY"]
          ),
          status: status,
          Remarks: ""
        }
      ],
      approverUsers: draft.multiApprovalDetails?.approverUsers || {},
      rejectedUsers: draft.multiApprovalDetails?.rejectedUsers || {},
      approvalRequiredFor: draft.multiApprovalDetails?.approvalRequiredFor || ""
    };

    if (status === APPROVAL_STATUS.APPROVED) {
      multiApprovalHistory.approverUsers[currentLevel] = (
        multiApprovalHistory.approverUsers[currentLevel] || []
      ).concat(loggedInUserEmail);
      multiApprovalHistory.rejectedUsers[currentLevel] =
        multiApprovalHistory.rejectedUsers[currentLevel] || [];
    } else {
      multiApprovalHistory.rejectedUsers[currentLevel] = (
        multiApprovalHistory.rejectedUsers[currentLevel] || []
      ).concat(loggedInUserEmail);
      multiApprovalHistory.approverUsers[currentLevel] =
        multiApprovalHistory.approverUsers[currentLevel] || [];
    }

    if (
      Utility.isEmptyObject(approvalFlowNode?.fields) ||
      status === APPROVAL_STATUS.REJECTED
    )
      return { multiApproveRequired, multiApprovalHistory };

    if (approvalFlowNode.fields[0].multiApprovalDetails) {
      let currentLevelDetails =
        approvalFlowNode.fields[0].multiApprovalDetails.filter(
          (approverLevel) => approverLevel.level === currentLevel
        );
      condition = currentLevelDetails[0].condition;
    }

    let approverArray = [];
    let levels = approvalFlowNode.fields[0].multiApprovalDetails?.length || 1;

    if (
      status === APPROVAL_STATUS.APPROVED &&
      levels === currentLevel &&
      condition === "ANY"
    ) {
      multiApproveRequired = false;
    } else {
      multiApproveRequired = true;
    }

    if (multiApproveRequired) {
      approverArray = approvalFlowNode.fields[0].multiApprovalDetails?.filter(
        (approverLevel) => approverLevel.level === currentLevel
      )[0]?.approver;
    }

    if (Utility.isEmptyObject(draft.multiApprovalDetails?.approvalHistory)) {
      if (multiApproveRequired) {
        let isCurrentLevelIncrease =
          condition === "ALL"
            ? approverArray.every((approver) =>
                multiApprovalHistory.approverUsers[currentLevel]?.includes(
                  approver
                )
              )
            : approverArray.some((approver) =>
                multiApprovalHistory.approverUsers[currentLevel]?.includes(
                  approver
                )
              );

        /* if single level flow, with condition set to ALL,
        and all users approved, then no further approval required */
        if (currentLevel === levels && isCurrentLevelIncrease) {
          multiApproveRequired = false;
        }

        currentLevel = isCurrentLevelIncrease
          ? levels === currentLevel
            ? currentLevel
            : currentLevel + 1
          : currentLevel;
      }
      multiApprovalHistory["currentLevel"] = currentLevel;
    } else {
      let users = [loggedInUserEmail];
      multiApprovalHistory.approvalHistory.forEach((history) => {
        if (history.level === multiApprovalHistory.currentLevel) {
          if (
            !users.includes(history.approvedBy) &&
            history.status === status
          ) {
            users.push(history.approvedBy);
          }
        }
      });
      if (status === APPROVAL_STATUS.APPROVED) {
        multiApprovalHistory["approverUsers"][currentLevel] = users;

        /* As approver can reapprove after rejecting a quote once */
        multiApprovalHistory["rejectedUsers"][currentLevel] =
          multiApprovalHistory["rejectedUsers"][currentLevel]?.filter(
            (userEmail: string) => userEmail !== loggedInUserEmail
          ) || [];
      } else {
        multiApprovalHistory["rejectedUsers"][currentLevel] = users;
      }

      let isCurrentLevelIncrease =
        condition === "ALL"
          ? approverArray.every((approver) =>
              multiApprovalHistory["approverUsers"][currentLevel]?.includes(
                approver
              )
            )
          : approverArray.some((approver) =>
              multiApprovalHistory["approverUsers"][currentLevel]?.includes(
                approver
              )
            );

      if (currentLevel === levels && isCurrentLevelIncrease) {
        multiApproveRequired = false;
      } else if (isCurrentLevelIncrease) {
        currentLevel = currentLevel + 1;
        multiApprovalHistory["currentLevel"] = currentLevel;
      }
    }
    return { multiApproveRequired, multiApprovalHistory };
  }

  static getQuotePayloadFromDraftData = (draftData, approvalStatus) => {
    const updatedDraftData = {
      ...draftData,
      documentDate: DateUtil.getFormattedDateString(
        draftData?.documentDate,
        DATE_FORMATS["DD-MM-YYYY"],
        DateUtil.getOrgDateFormat()
      ),
      shipByDate: DateUtil.getFormattedDateString(
        draftData?.shipByDate,
        DATE_FORMATS["DD-MM-YYYY"],
        DateUtil.getOrgDateFormat()
      ),
      validTillDate: DateUtil.getFormattedDateString(
        draftData?.validTillDate,
        DATE_FORMATS["DD-MM-YYYY"],
        DateUtil.getOrgDateFormat()
      )
    };
    const quoteDocument =
      BooksHelper.getQuoteDocumentFromPayload(updatedDraftData);
    const quotePayload = BooksHelper.prepareQuotationPayload(
      quoteDocument,
      draftData.contactDto
    );

    if (!Utility.isEmptyObject(updatedDraftData.shipFrom)) {
      quotePayload.shipFrom = updatedDraftData.shipFrom;
    }

    if (approvalStatus === APPROVAL_STATUS.APPROVED) {
      quotePayload.locked = true;
    }

    if (updatedDraftData?.createdUserEmail) {
      quotePayload.createdUserName = updatedDraftData.createdUserName;
      quotePayload.createdUserEmail = updatedDraftData.createdUserEmail;
    }

    return quotePayload;
  };

  static onApproveDraft = async (
    rowData: any,
    markedStatus: APPROVAL_STATUS,
    draftType: string = LABELS.QUOTES
  ) => {
    let { multiApproveRequired, multiApprovalHistory } =
      BooksHelper.checkMultiApprovalRequired(rowData, markedStatus);

    let draftData = {
      ...rowData,
      documentDate: DateUtil.getFormattedDateString(
        rowData?.documentDate,
        DateUtil.getOrgDateFormat(),
        DATE_FORMATS["DD-MM-YYYY"]
      ),
      shipByDate: DateUtil.getFormattedDateString(
        rowData?.shipByDate,
        DateUtil.getOrgDateFormat(),
        DATE_FORMATS["DD-MM-YYYY"]
      ),
      validTillDate: DateUtil.getFormattedDateString(
        rowData?.validTillDate,
        DateUtil.getOrgDateFormat(),
        DATE_FORMATS["DD-MM-YYYY"]
      ),
      status: rowData.status?.toString() || "OPEN",
      fulfillmentStatus: rowData.fulfillmentStatus?.toString() || "UNFULFILLED",
      [COLUMN_CODE.BOOKS_QUOTE.DOCUMENT_TYPE]:
        rowData.recurring?.toString() === RECURRING_DOCUMENT_TYPE.RECURRING,
      multiApprovalDetails: multiApprovalHistory
    };
    try {
      let isPendingForApproval = false;

      if (markedStatus === APPROVAL_STATUS.APPROVED && multiApproveRequired) {
        draftData["approvalStatus"] = APPROVAL_STATUS.PENDING_FOR_APPROVAL;
        isPendingForApproval = true;
      } else {
        draftData["approvalStatus"] = markedStatus;
      }

      if (multiApproveRequired && draftData.isDraftDocument) {
        const dataToSave = BooksHelper.getDraftDocumentPayload(
          draftData,
          draftType,
          true
        );
        await BooksService.updateDraftRecord(dataToSave, draftData.draftId);
        isPendingForApproval &&
          BooksHelper.sendTriggerOnApproval(draftData, DOC_TYPE.QUOTE, "u");
      } else if (draftData.id) {
        const payload = {
          approvalStatus: draftData.approvalStatus,
          multiApprovalDetails: multiApprovalHistory,
          locked: true
        };
        await BooksService.patchUpdateQuote(draftData.id, payload);
        isPendingForApproval &&
          BooksHelper.sendTriggerOnApproval(draftData, DOC_TYPE.QUOTE, "u");
      } else if (markedStatus !== APPROVAL_STATUS.REJECTED) {
        const quotePayload = BooksHelper.getQuotePayloadFromDraftData(
          draftData,
          markedStatus
        );
        const savedQuote: any = await BooksService.createQuote(quotePayload);

        draftData.documentCode = savedQuote.documentSequenceCode;
        const dataToSave = BooksHelper.getDraftDocumentPayload(
          draftData,
          draftType,
          true
        );
        await BooksService.updateDraftRecord(dataToSave, draftData.draftId);
      }
    } catch (err) {
      console.error("Error while approving draft", err);
      return false;
    }

    return true;
  };

  static sendTriggerOnApproval = (
    payload: IQuotation,
    documentType: DOC_TYPE = DOC_TYPE.QUOTE,
    opName: string = "c"
  ) => {
    let emails = BooksHelper.getApproverEmail(payload, documentType);
    const loggedInUserInfo = store
      .getState()
      ?.tenant?.usersInfo?.content?.find(
        (user) => user.id === UserManager.getUserID()
      );
    let payloadObj = {
      contactName: payload.contactDto?.name || payload.contact?.name || "",
      contactCode: payload.contactCode,
      crm3DealId: payload.crm3DealId || "",
      documentSeqCode:
        (payload.isDraftDocument
          ? payload.draftCode
          : payload.documentSequenceCode) || "",
      totalAmount: getNumber(payload?.totalAmount || 0),
      userName: `${loggedInUserInfo?.firstName || ""} ${
        loggedInUserInfo?.lastName || ""
      }`,
      currency: CURRENCY_SYMBOLS[payload.currency] || payload.currency,
      currencyCode: payload.currency,
      opName: opName,
      approverMap: Object.fromEntries(emails),
      currentLevel: payload["multiApprovalDetails"]?.currentLevel || 1,
      documentCode: payload.quotationCode,
      approvalHistory: [], // don't send approval history to avoid email not sent issues
      documentLink: `${process.env.REACT_APP_URL_CRM_PLUS}${PAGE_ROUTES.QUOTES.slice(1)}${payload.quotationCode ? `/${payload.quotationCode}` : ""}`
    };
    BooksService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error("Error while creating draft: ", err);
      }
    );
  };
  static isSalesOrderVisible = () => {
    const country = store.getState()?.books?.tenantsDetails?.country;
    const isEnableSaleOrder = store.getState()?.books?.tenantsDetails
      ?.additionalSettings?.ENABLE_SO
      ? store.getState()?.books?.tenantsDetails?.additionalSettings?.ENABLE_SO
      : false;
    return (
      COUNTRIES_ALLOWED_FOR_SALES_ORDER.includes(country) && isEnableSaleOrder
    );
  };
  static getBaseUomName(findUOM: any) {
    let uomToFind = findUOM.sourceUOM ? findUOM.sourceUOM : findUOM.id;

    let filtered = store
      .getState()
      .books.uoms.filter((uomobj: any) => uomobj.id === uomToFind);
    if (!Utility.isEmptyObject(filtered)) {
      return filtered[0];
    } else {
      return { name: "" };
    }
  }
  static getMessageForSalesDocument(
    docType: string,
    doc: any,
    link: string,
    tenantName: string
  ) {
    const msg = `Hello,
Thank you for reaching out to us. Please find the
${docType} [${doc.documentSequenceCode}] link attached with this message, in response to your inquiry.

Kindly review the same and contact us for any further queries or details.

${docType} No: ${doc.documentSequenceCode}
Send By: ${tenantName}
Amount: ${doc.currency} ${doc.totalAmount}

${docType} link: ${link}

We look forward to doing business with you.
Thanks & Regards,
${tenantName}`;

    return msg;
  }
}
export enum MODULE_NAME_FOR_STORAGE_UTILITY {
  SELL = "SELL",
  BUY = "BUY"
}
export const getNewColumnForCF = (item: any, editable = true) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.system ? getCapitalized(item.label?.toLowerCase()) : item.label,
    type: getColumnConfigType(item?.fieldType),
    width: 100,
    systemField: true,
    editable: editable,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig:
      item?.fieldType !== BOOKS_CUSTOM_FIELD_TYPE.DROPDOWN &&
      item?.fieldType !== BOOKS_CUSTOM_FIELD_TYPE.USER
        ? null
        : {
            title: "",
            allowSearch: false,
            searchableKey: "name",
            style: { minWidth: 100 },
            className: "shadow-m width-auto",
            data: !Utility.isEmptyObject(item?.attributes)
              ? item?.attributes.map((i: any) => i)
              : [],
            renderer: (index: any, obj: any) => {
              return obj?.value;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }
  };

  return newItem;
};
export const getColumnConfigType = (type: any) => {
  const UPDATED_INPUT_TYPE = {
    ...INPUT_TYPE,
    USER: "user"
  };
  switch (type?.toLowerCase()) {
    case UPDATED_INPUT_TYPE.TEXT.toLowerCase():
      return UPDATED_INPUT_TYPE.TEXT.toLowerCase();
    case UPDATED_INPUT_TYPE.NUMBER.toLowerCase():
      return UPDATED_INPUT_TYPE.NUMBER.toLowerCase();
    case UPDATED_INPUT_TYPE.DROPDOWN.toLowerCase():
      return UPDATED_INPUT_TYPE.DROPDOWN.toLowerCase();
    case UPDATED_INPUT_TYPE.DATE.toLowerCase():
      return UPDATED_INPUT_TYPE.DATE.toLowerCase();
    case UPDATED_INPUT_TYPE.USER.toLowerCase():
      return UPDATED_INPUT_TYPE.DROPDOWN.toLowerCase();

    default:
      return UPDATED_INPUT_TYPE.TEXT.toLowerCase();
  }
};
export const getNewCFItem = (item: any, filteredCF: any, editable = true) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.label,
    type: getColumnConfigType(filteredCF?.fieldType),
    width: 100,
    systemField: true,
    editable: editable,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig:
      filteredCF?.fieldType !== BOOKS_CUSTOM_FIELD_TYPE.DROPDOWN &&
      filteredCF?.fieldType !== BOOKS_CUSTOM_FIELD_TYPE.USER
        ? null
        : {
            title: "",
            allowSearch: false,
            searchableKey: "name",
            style: { minWidth: 100 },
            className: "shadow-m width-auto",
            data: !Utility.isEmpty(filteredCF?.attributes)
              ? filteredCF?.attributes.map((i: any) => i)
              : [],
            renderer: (index: any, obj: any) => {
              return obj?.value;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }
  };

  return newItem;
};

function getIndiaSalesTaxTypeForComposition(
  shipFromState: any,
  shipToState: any
) {
  if (isShipFromAndShipToStateSame(shipFromState, shipToState)) {
    return GST_TYPE.EXEMPT;
  } else {
    return getIndiaDefaultTaxType(shipFromState, shipToState);
  }
}

function getSalesTaxTypeIndiaForRegisteredSez(
  contactType: any,
  shipFromState: any,
  shipToState: any
) {
  if (
    CUST_SEZ_W_PAY === contactType ||
    CUST_NA === contactType ||
    CUST_EXPORT_W_PAY === contactType
  ) {
    return GST_TYPE.INTER;
  } else if (
    CUST_SEZ_WO_PAY === contactType ||
    CUST_EXPORT_WO_PAY === contactType
  ) {
    return GST_TYPE.EXEMPT;
  } else {
    return getIndiaDefaultTaxType(shipFromState, shipToState);
  }
}

function getSalesTaxTypeIndiaForUnregisteredSez(
  shipFromState: any,
  shipToState: any,
  contactType: any
) {
  if (
    CUST_NA === contactType &&
    isShipFromAndShipToStateSame(shipFromState, shipToState)
  ) {
    return GST_TYPE.INTRA;
  } else if (
    (CUST_NA === contactType &&
      isShipFromAndShipToStateSame(shipFromState, shipToState)) ||
    CUST_EXPORT_W_PAY === contactType
  ) {
    return GST_TYPE.INTER;
  } else if (CUST_EXPORT_WO_PAY === contactType) {
    return GST_TYPE.EXEMPT;
  } else {
    return getIndiaDefaultTaxType(shipFromState, shipToState);
  }
}

const buildAddress = (address: IContactAddress): any => {
  if (address) {
    return {
      contactName: address.contactName,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      country: address.country,
      postalCode: address.postalCode,
      preferred: address.preferred,
      state: address.state
    };
  } else {
    return null;
  }
};

function getIndiaDefaultTaxType(shipFromState: any, shipToState: any) {
  return isShipFromAndShipToStateSame(shipFromState, shipToState)
    ? GST_TYPE.INTRA
    : GST_TYPE.INTER;
}

function isShipFromAndShipToStateSame(shipFromState: any, shipToState: any) {
  return (
    shipFromState &&
    shipToState &&
    shipFromState.toUpperCase() === shipToState.toUpperCase()
  );
}

export const getPreferredAddress = (entity: any, type: string) => {
  let address = null;
  if (entity && entity[type] && entity[type].length > 0) {
    address = entity[type].find((addr: any) => addr && addr.preferred);
    if (!address) {
      address = entity[type][0];
    }
    address = buildAddress(address);
  }
  return address;
};

export function checkSezForSales(
  shipToState: string,
  contactType: string,
  gstTreatment: string,
  tenantState?: string
) {
  const tenantDetails = store.getState()?.books?.tenantsDetails;
  const isRegisteredToSez = tenantDetails.sezOrImportExport;
  const isRegisteredToComposition = tenantDetails.registeredToCompositionScheme;
  const tenantShipAddress = getPreferredAddress(
    tenantDetails,
    "shippingAddresses"
  );
  let shipFromState = tenantShipAddress?.state;
  if (!Utility.isEmptyObject(tenantState)) {
    shipFromState = tenantState;
  }
  if (contactType === undefined || contactType === null) {
    return getIndiaDefaultTaxType(shipFromState, shipToState);
  }

  if (gstTreatment === SPECIAL_ECONOMIC_ZONE) {
    if (contactType === CUST_SEZ_W_PAY) {
      return GST_TYPE.INTER;
    }

    if (contactType === CUST_SEZ_WO_PAY) {
      return GST_TYPE.EXEMPT;
    }
  } else if (
    gstTreatment === REGISTERED_BUSINESS_REGULAR &&
    contactType === CUST_SEZ_WO_PAY
  ) {
    // ZEN-5777
    return GST_TYPE.EXEMPT;
  } else if (isRegisteredToSez) {
    return getSalesTaxTypeIndiaForRegisteredSez(
      contactType,
      shipFromState,
      shipToState
    );
  } else if (isRegisteredToComposition) {
    return getIndiaSalesTaxTypeForComposition(shipFromState, shipToState);
  } else if (
    !isRegisteredToSez &&
    !isRegisteredToComposition &&
    (CUST_NA === contactType ||
      CUST_EXPORT_W_PAY === contactType ||
      CUST_EXPORT_WO_PAY === contactType)
  ) {
    return getSalesTaxTypeIndiaForUnregisteredSez(
      shipFromState,
      shipToState,
      contactType
    );
  } else {
    return getIndiaDefaultTaxType(shipFromState, shipToState);
  }
}

export const roundingOff = (val: any, precisionVal = CURRENCY_PRECISION) => {
  val = Number(val);
  val = val + 1 / Math.pow(10, precisionVal + 10);
  var newnumber =
    Math.round(val * Math.pow(10, precisionVal)) / Math.pow(10, precisionVal);
  return newnumber;
};

export const roundingOffStr = (val: any, precisionVal = CURRENCY_PRECISION) => {
  const num = roundingOff(val, precisionVal);
  return num.toFixed(precisionVal);
};

export const convertToCurrentExchangeRate = (
  exchangeRate: number,
  previousExchangeRate: number,
  value: any
) => {
  return Utility.roundOff(
    (exchangeRate / (previousExchangeRate || 1)) * Number(value),
    CURRENCY_PRECISION
  );
};
