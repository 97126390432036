export const PERMISSIONS = [
  {
    title: `No access`,
    permissions_level: 0,
    description: `None`
  },
  {
    title: `Can view`,
    permissions_level: 1,
    description: `Can't edit or share with others`
  },
  {
    title: `Can edit`,
    permissions_level: 2,
    description: `Can view & edit but can't share with others`
  },
  {
    title: `Full access`,
    permissions_level: 3,
    description: `Can view & edit, can share with others`
  }
];
export enum INVITE_USER_STATUS {
  SENT = "SENT",
  JOINED = "JOINED"
}
export const RESTRICTED_MODULE_TITLE = {
  CONTACT: "CONTACT",
  ACCOUNT: "ACCOUNT",
  QUOTE: "QUOTE",
  INVOICE: "INVOICE",
  PIPELINE: "PIPELINE",
  STAGE: "STAGE",
  DEAL: "DEAL",
  CAMPAIGN: "CAMPAIGN",
  FORM: "FORM",
  LANDING_PAGE: "LANDING PAGE",
  /* FUNNEL: "FUNNEL", */
  AUTOMATION: "AUTOMATION",
  ACTIVITY: "ACTIVITY",
  CHAT_MESSAGE: "CHAT",
  TICKET: "TICKET",
  PERMISSION: "PERMISSION",
  BOOKS_PRODUCT_GROUP: "PRODUCT GROUP",
  BOOKS_QUOTE_GROUP: "QUOTE GROUP",
  BOOKS_PRICE_LIST: "PRICE BOOK",
  REPORT: "Smart Report",
  SETTING: "SETTINGS"
};

export const USER_ACTION_TYPES = {
  REC_VIEW: "REC_VIEW",
  REC_CREATE: "REC_CREATE",
  REC_UPDATE: "REC_UPDATE",
  REC_DELETE: "REC_DELETE",
  REC_IMPORT: "REC_IMPORT",
  REC_EXPORT: "REC_EXPORT",
  REC_PRINT: "REC_PRINT",
  FIELD_CREATE: "FIELD_CREATE",
  FIELD_UPDATE: "FIELD_UPDATE",
  FIELD_DELETE: "FIELD_DELETE",
  CHANGE_REQUIRED_FIELDS: "CHANGE_REQUIRED_FIELDS",
  REC_EMAIL: "REC_EMAIL",
  REC_WHATSAPP: "REC_WHATSAPP",
  EDIT_TEMPLATE: "EDIT_TEMPLATE",
  TICKET_REPLY: "TICKET_REPLY",
  LINK_EMAIL: "LINK_EMAIL",
  UNLINK_EMAIL: "UNLINK_EMAIL",
  ASSIGN_ROLE: "ASSIGN_ROLE",
  ASSIGN_OWNER_SUBOWNER_TEAM: "ASSIGN_OWNER_SUBOWNER_TEAM",
  ASSIGN_CONTACT: "ASSIGN_CONTACT",
  LOOKUP_FIELD_CREATE: "LOOKUP_FIELD_CREATE",
  LOOKUP_FIELD_UPDATE: "LOOKUP_FIELD_UPDATE",
  NOTE_CREATE: "NOTE_CREATE",
  NOTE_UPDATE: "NOTE_UPDATE",
  NOTE_DELETE: "NOTE_DELETE",
  FIELD_REORDER: "FIELD_REORDER",
  DOCUMENT_SEQUENCE_CODE_EDITABLE: "DOCUMENT_SEQUENCE_CODE_EDITABLE",
  QUOTE_APPROVAL_LOCK_ENABED: "QUOTE_APPROVAL_LOCK_ENABED",
  QUOTE_EDIT_BLOCK_MULTI_USER: "QUOTE_EDIT_BlOCK_MULTI_USER",
  QUOTE_APPROVAL_RESTART_ON_REJECT: "QUOTE_APPROVAL_RESTART_ON_REJECT",
  QUOTE_ACTIONS: "QUOTE_ACTIONS",
  ASSIGN_PRODUCT: "ASSIGN_PRODUCT",
  ASSIGN_TO_ACCOUNT: "ASSIGN_TO_ACCOUNT",
  ASSIGN_TO_CONTACT: "ASSIGN_TO_CONTACT",
  ASSIGN_TO_QUOTE: "ASSIGN_TO_QUOTE",
  USER_PERMISSION: "USER_PERMISSION",
  TEAM_PERMISSION: "TEAM_PERMISSION",
  ORGANIZATION: "ORGANIZATION",
  SUBSCRIPTION_BILLING: "SUBSCRIPTION_BILLING",
  CURRENCY: "CURRENCY",
  DATE: "DATE",
  DUPLICATION: "DUPLICATION",
  CONTACT_SEQ_CODE: "CONTACT_SEQ_CODE",
  CLEAR_CONTACTS: "CLEAR_CONTACTS",
  PAYMENT_INTEGRATION: "PAYMENT_INTEGRATION",
  CHAT: "CHAT",
  NOTIFICATION: "NOTIFICATION",
  INDIAMART_INTEGRATION: "INDIAMART_INTEGRATION",
  JUSTDIAL_INTEGRATION: "JUSTDIAL_INTEGRATION",
  EMAIL_ALERT: "EMAIL_ALERT",
  EMAIL_SYNC: "EMAIL_SYNC",
  PRODUCT_GROUPING: "PRODUCT_GROUPING",
  ACCOUNT_LINKAGE: "ACCOUNT_LINKAGE",
  CONTACT_LINKAGE_TO_QUOTE_DEAL: "CONTACT_LINKAGE_TO_QUOTE_DEAL",
  UPLIFT_DONWLIFT_QUOTE: "UPLIFT_DONWLIFT_QUOTE",
  PRICE_BOOK_CRITERIA_MODULE: "PRICE_BOOK_CRITERIA_MODULE",
  QUOTATION_SYNC: "QUOTATION_SYNC",
  SUPPORT_TICKET_AUTO_REPLY: "SUPPORT_TICKET_AUTO_REPLY",
  UPLIFT_ENABLED: "UPLIFT_ENABLED",
  DOWNLIFT_ENABLED: "DOWNLIFT_ENABLED",
  DRAFT_DOCUMENT_NUMBER_ALLOCATION: "DRAFT_DOCUMENT_NUMBER_ALLOCATION",
  AUTO_POPULATE_OWNER_SUB_OWNER: "AUTO_POPULATE_OWNER_SUB_OWNER",
  TICKET_ASSIGNEE_ROLES: "TICKET_ASSIGNEE_ROLES",
  TICKET_REQUESTOR_EMAIL_MATCH_CRITERIA:
    "TICKET_REQUESTOR_EMAIL_MATCH_CRITERIA",
  SMART_REPORT_CREATE: "SMART_REPORT_CREATE",
  SMART_REPORT_UPDATE: "SMART_REPORT_UPDATE",
  SMART_REPORT_DELETE: "SMART_REPORT_DELETE",
  ATTACHMENT_DOWNLOAD: "ATTACHMENT_DOWNLOAD",
  ATTACHMENT_UPLOAD: "ATTACHMENT_UPLOAD",
  ATTACHMENT_DELETE: "ATTACHMENT_DELETE",
  PRIMARY_PIPELINE_EDITABLE: "PRIMARY_PIPELINE_EDITABLE",
  DEFAULT_PIPELINE_VIEW: "DEFAULT_PIPELINE_VIEW"
};
export const MODULE_WISE_PERMISSION_TITLE = {
  ACCOUNT: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all accounts",
    [USER_ACTION_TYPES.REC_CREATE]: "Add accounts",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update accounts",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete accounts",
    [USER_ACTION_TYPES.REC_IMPORT]: "Import accounts",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export accounts",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field",
    [USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS]: "Change required fields",
    [USER_ACTION_TYPES.ASSIGN_CONTACT]: "Assign or remove a contact",
    [USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM]:
      "Assign Owner,Sub Owner,Team",
    [USER_ACTION_TYPES.LOOKUP_FIELD_CREATE]: "Create a lookup field",
    [USER_ACTION_TYPES.LOOKUP_FIELD_UPDATE]: "Update a lookup field",
    [USER_ACTION_TYPES.NOTE_CREATE]: "Create note",
    [USER_ACTION_TYPES.NOTE_UPDATE]: "Update note",
    [USER_ACTION_TYPES.NOTE_DELETE]: "Delete note",
    [USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD]: "Download attachment",
    [USER_ACTION_TYPES.ATTACHMENT_UPLOAD]: "Upload attachment",
    [USER_ACTION_TYPES.ATTACHMENT_DELETE]: "Delete attachment"
  },
  CONTACT: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all contacts",
    [USER_ACTION_TYPES.REC_CREATE]: "Add contacts",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update contacts",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete contacts",
    [USER_ACTION_TYPES.REC_IMPORT]: "Import contacts",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export contacts",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field",
    [USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS]: "Change required fields",
    [USER_ACTION_TYPES.REC_EMAIL]: "Email to contacts",
    [USER_ACTION_TYPES.REC_WHATSAPP]: "Whatsapp to contacts",
    [USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM]:
      "Assign Owner,Sub Owner and Team",
    [USER_ACTION_TYPES.LOOKUP_FIELD_CREATE]: "Create a lookup field",
    [USER_ACTION_TYPES.LOOKUP_FIELD_UPDATE]: "Update a lookup field",
    [USER_ACTION_TYPES.NOTE_CREATE]: "Create note",
    [USER_ACTION_TYPES.NOTE_UPDATE]: "Update note",
    [USER_ACTION_TYPES.NOTE_DELETE]: "Delete note",
    [USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD]: "Download attachment",
    [USER_ACTION_TYPES.ATTACHMENT_UPLOAD]: "Upload attachment",
    [USER_ACTION_TYPES.ATTACHMENT_DELETE]: "Delete attachment"
  },
  DEAL: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all deals",
    [USER_ACTION_TYPES.REC_CREATE]: "Add deals",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update deals",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete deals",
    [USER_ACTION_TYPES.REC_IMPORT]: "Import deals",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export deals",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field",
    [USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS]: "Change required fields",
    [USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM]:
      "Assign Owner,Sub Owner and Team",
    [USER_ACTION_TYPES.LOOKUP_FIELD_CREATE]: "Create a lookup field",
    [USER_ACTION_TYPES.LOOKUP_FIELD_UPDATE]: "Update a lookup field",
    [USER_ACTION_TYPES.NOTE_CREATE]: "Create note",
    [USER_ACTION_TYPES.NOTE_UPDATE]: "Update note",
    [USER_ACTION_TYPES.NOTE_DELETE]: "Delete note",
    [USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD]: "Download attachment",
    [USER_ACTION_TYPES.ATTACHMENT_UPLOAD]: "Upload attachment",
    [USER_ACTION_TYPES.ATTACHMENT_DELETE]: "Delete attachment"
  },
  PIPELINE: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all pipelines",
    [USER_ACTION_TYPES.REC_CREATE]: "Add pipelines",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update pipelines",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete pipelines",
    [USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM]:
      "Assign Owner,Sub Owner and Team",
    [USER_ACTION_TYPES.PRIMARY_PIPELINE_EDITABLE]: "Assign default pipeline"
  },
  STAGE: {
    [USER_ACTION_TYPES.REC_CREATE]: "Add stages",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update stages",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete stages"
  },
  ACTIVITY: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all activities",
    [USER_ACTION_TYPES.REC_CREATE]: "Add activities",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update activities",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete activities",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export activities",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field",
    [USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS]: "Change required fields",
    [USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM]:
      "Assign Owner,Sub Owner and Team",
    [USER_ACTION_TYPES.LOOKUP_FIELD_CREATE]: "Create a lookup field",
    [USER_ACTION_TYPES.LOOKUP_FIELD_UPDATE]: "Update a lookup field"
  },
  QUOTE: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all quotes",
    [USER_ACTION_TYPES.REC_CREATE]: "Add quotes",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update quotes",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete quotes",
    [USER_ACTION_TYPES.QUOTE_APPROVAL_LOCK_ENABED]: "Lock/Unlock quotes",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export quotes",
    [USER_ACTION_TYPES.REC_PRINT]: "Print quotes",
    [USER_ACTION_TYPES.EDIT_TEMPLATE]: "Edit design/templates",
    [USER_ACTION_TYPES.REC_EMAIL]: "Email quotes",
    [USER_ACTION_TYPES.DOCUMENT_SEQUENCE_CODE_EDITABLE]:
      "Edit auto numbering sequence",
    [USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS]: "Change required fields",
    [USER_ACTION_TYPES.UPLIFT_ENABLED]: "Edit uplift price",
    [USER_ACTION_TYPES.DOWNLIFT_ENABLED]: "Edit downlift price",
    [USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD]: "Download attachment",
    [USER_ACTION_TYPES.ATTACHMENT_UPLOAD]: "Upload attachment",
    [USER_ACTION_TYPES.ATTACHMENT_DELETE]: "Delete attachment"
  },
  INVOICE: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all invoices",
    // [USER_ACTION_TYPES.REC_CREATE]: "Add invoices",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update invoices",
    // [USER_ACTION_TYPES.REC_DELETE]: "Delete invoices",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export invoices",
    [USER_ACTION_TYPES.REC_PRINT]: "Print invoices",
    [USER_ACTION_TYPES.EDIT_TEMPLATE]: "Edit design/templates",
    [USER_ACTION_TYPES.REC_EMAIL]: "Email invoices"
  },
  BOOKS_PRICE_LIST: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all price books",
    [USER_ACTION_TYPES.REC_CREATE]: "Add price book",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update price books",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete price books",
    [USER_ACTION_TYPES.ASSIGN_TO_ACCOUNT]: "Assign to Account",
    [USER_ACTION_TYPES.ASSIGN_TO_CONTACT]: "Assign to Contact",
    [USER_ACTION_TYPES.ASSIGN_TO_QUOTE]: "Assign to Quote",
    [USER_ACTION_TYPES.REC_IMPORT]: "Import price book",
    [USER_ACTION_TYPES.REC_EXPORT]: "Export price book"
  },
  BOOKS_QUOTE_GROUP: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all quote groups",
    [USER_ACTION_TYPES.REC_CREATE]: "Add quote groups",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update quote groups",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete quote groups"
  },
  BOOKS_PRODUCT_GROUP: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all product groups",
    [USER_ACTION_TYPES.REC_CREATE]: "Add product groups",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update product groups",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete product groups",
    [USER_ACTION_TYPES.ASSIGN_PRODUCT]: "Assign product to group"
  },
  FORM: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all forms",
    [USER_ACTION_TYPES.REC_CREATE]: "Add forms",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update forms",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete forms",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field"
  },
  CAMPAIGN: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all campaigns",
    [USER_ACTION_TYPES.REC_CREATE]: "Add campaigns",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update campaigns",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete campaigns",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field"
  },
  LANDING_PAGE: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all landing pages",
    [USER_ACTION_TYPES.REC_CREATE]: "Add landing pages",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update landing pages",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete landing pages",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field"
  },
  /* FUNNEL: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all funnels",
    [USER_ACTION_TYPES.REC_CREATE]: "Add funnels",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update funnels",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete funnels",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field",
  }, */
  TICKET: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all ticket",
    [USER_ACTION_TYPES.REC_CREATE]: "Add ticket",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update ticket",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete ticket",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Add custom field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update custom field",
    [USER_ACTION_TYPES.FIELD_DELETE]: "Delete custom field",
    [USER_ACTION_TYPES.TICKET_REPLY]: "Reply to tickets",
    [USER_ACTION_TYPES.LINK_EMAIL]: "Link an email",
    [USER_ACTION_TYPES.UNLINK_EMAIL]: "Unlink emails",
    [USER_ACTION_TYPES.NOTE_CREATE]: "Create note",
    [USER_ACTION_TYPES.NOTE_UPDATE]: "Update note",
    [USER_ACTION_TYPES.NOTE_DELETE]: "Delete note",
    [USER_ACTION_TYPES.FIELD_REORDER]: "Field reorder",
    [USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS]: "Change required fields",
    [USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD]: "Download attachment",
    [USER_ACTION_TYPES.ATTACHMENT_UPLOAD]: "Upload attachment",
    [USER_ACTION_TYPES.ATTACHMENT_DELETE]: "Delete attachment"
  },
  CHAT_MESSAGE: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all chats"
  },
  AUTOMATION: {
    [USER_ACTION_TYPES.REC_VIEW]: "View all automations",
    [USER_ACTION_TYPES.REC_CREATE]: "Add automations",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update automations",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete automations",
    [USER_ACTION_TYPES.FIELD_CREATE]: "Create a field",
    [USER_ACTION_TYPES.FIELD_UPDATE]: "Update a field"
  },
  PERMISSION: {
    [USER_ACTION_TYPES.REC_CREATE]: "Add new role",
    [USER_ACTION_TYPES.REC_UPDATE]: "Update a role",
    [USER_ACTION_TYPES.REC_DELETE]: "Delete a role",
    [USER_ACTION_TYPES.ASSIGN_ROLE]: "Assign roles to users"
  },
  REPORT: {
    [USER_ACTION_TYPES.SMART_REPORT_CREATE]: "Add report",
    [USER_ACTION_TYPES.SMART_REPORT_UPDATE]: "Update report",
    [USER_ACTION_TYPES.SMART_REPORT_DELETE]: "Delete report"
  },
  SETTING: {
    [USER_ACTION_TYPES.USER_PERMISSION]: "User permissions",
    [USER_ACTION_TYPES.TEAM_PERMISSION]: "Team permissions",
    [USER_ACTION_TYPES.ORGANIZATION]: "Organization Settings",
    [USER_ACTION_TYPES.SUBSCRIPTION_BILLING]: "Subscription/Billing",
    [USER_ACTION_TYPES.CURRENCY]: "Currency Settings",
    [USER_ACTION_TYPES.DATE]: "Date Settings",
    [USER_ACTION_TYPES.DUPLICATION]: "Duplication Settings",
    [USER_ACTION_TYPES.CONTACT_SEQ_CODE]: "Contact Sequence Code Settings",
    [USER_ACTION_TYPES.CLEAR_CONTACTS]: "Clear Contacts",
    [USER_ACTION_TYPES.PAYMENT_INTEGRATION]: "Integrate Payments",
    [USER_ACTION_TYPES.CHAT]: "Chat Settings",
    [USER_ACTION_TYPES.NOTIFICATION]: "Notification Settings",
    [USER_ACTION_TYPES.INDIAMART_INTEGRATION]: "Connect with IndiaMart",
    [USER_ACTION_TYPES.JUSTDIAL_INTEGRATION]: "Connect with JustDial",
    [USER_ACTION_TYPES.EMAIL_ALERT]: "Email Alert Settings",
    [USER_ACTION_TYPES.EMAIL_SYNC]: "Email Sync restart",
    [USER_ACTION_TYPES.PRODUCT_GROUPING]: "Enable Product Grouping",
    [USER_ACTION_TYPES.ACCOUNT_LINKAGE]: "Enable Account linkage",
    [USER_ACTION_TYPES.CONTACT_LINKAGE_TO_QUOTE_DEAL]:
      "Contact Linkage to Quote/Deal",
    [USER_ACTION_TYPES.UPLIFT_DONWLIFT_QUOTE]: "Uplift/Downlift Quote Price",
    [USER_ACTION_TYPES.PRICE_BOOK_CRITERIA_MODULE]:
      "Price Book Module Selection",
    [USER_ACTION_TYPES.QUOTATION_SYNC]: "Quote Sync Setting",
    [USER_ACTION_TYPES.QUOTE_APPROVAL_LOCK_ENABED]: "Quote Approval Lock",
    [USER_ACTION_TYPES.QUOTE_EDIT_BLOCK_MULTI_USER]:
      "Quote Block Edit By Multi Users",
    [USER_ACTION_TYPES.QUOTE_APPROVAL_RESTART_ON_REJECT]:
      "Quote approval restart on rejection",
    [USER_ACTION_TYPES.QUOTE_ACTIONS]: "Quote actions setting",
    [USER_ACTION_TYPES.SUPPORT_TICKET_AUTO_REPLY]:
      "Support Ticket Auto Reply Email",
    [USER_ACTION_TYPES.DRAFT_DOCUMENT_NUMBER_ALLOCATION]:
      "Draft Document Number Allocation",
    [USER_ACTION_TYPES.DRAFT_DOCUMENT_NUMBER_ALLOCATION]:
      "Auto Populate Owner/ Sub Owner",
    [USER_ACTION_TYPES.TICKET_ASSIGNEE_ROLES]:
      "Support Ticket Role Based Assignees",
    [USER_ACTION_TYPES.TICKET_REQUESTOR_EMAIL_MATCH_CRITERIA]:
      "Support Ticket Requestor Email Criteria",
    [USER_ACTION_TYPES.DEFAULT_PIPELINE_VIEW]: "Default pipeline selection"
  }
};

export const NO_USER_VIEW_ALERT =
  "Looks like you don't have permission to view organization users, please contact your organization owner to provide Deskera CRM+ product access.";

export const NO_TEAM_VIEW_ALERT =
  "Looks like you don't have any teams currently, please create a team from settings to continue.";

export enum TEAM_MEMBER_ACTION {
  ADD = "add",
  REMOVE = "remove"
}

export enum TEAMS {
  MEMBER = "MEMBER",
  TEAM_LEAD = "TEAM_LEAD"
}

export const TEAM_MEMBER_ERROR_MSG = "No members added!";
export const TEAM_MEMBER_ERROR_MSG_AT_LEAST_ONE =
  "Add at-least one member to this team to proceed. Team can't be created without a member.";
export const DELETE_TEAM = "Delete Team?";
export const DELETE_TEAM_MSG =
  "Deleting this team will delete it permanently you will not be able to restore it.";

export const NO_TEAM_LEAD = "No team lead selected!";
export const NO_TEAM_LEAD_MSG =
  "Select team lead to proceed. Team can't be created without a team lead.";
