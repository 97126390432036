import { Component } from "react";
import ReactDOM from "react-dom";
import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_TYPE,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  DKIcons,
  DKIcon,
  showAlert
} from "deskera-ui-library";
import SegmentControl from "../common/SegmentControl";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import ActivityManager from "../../managers/ActivityManager";
import Utility, { getRandomHexString } from "../../utility/Utility";
import DKInputDateTime from "../common/DKInputDateTime";
import Table from "../../services/table";
import { store } from "../../redux/store";
import {
  addRecord,
  deleteRecord,
  fetchRecordsByTable,
  updateRecord
} from "../../redux/slices/recordSlice";
import ApiConstants from "../../constants/ApiConstants";
import UserManager from "../../managers/UserManager";
import TableDataParser from "../../Helper/TableDataParser";
import DataGridUtility from "../../utility/DataGridUtility";
import { showAddColumnPopup } from "../common/AddColumnPopup";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import PermissionService from "../../services/common/permission";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../../utility/Date";
import {
  INPUT_TYPE_TEXT_AREA,
  TEXT_AREA_DEFAULT_LENGTH
} from "../../constants/Constant";

export interface IAddActivityPopupProps {
  isEdit: boolean;
  selectedActivity: any;
  module: string;
  popupId: any;
  recordId: any;
  moduleRecordId: any;
  startDate: any;
  endDate: any;
  accountId?: any;
  isFetchCalled: boolean;
  onSubmit?: (res?: any) => void;
  onCancel?: () => void;
}

export interface IAddActivityPopupState {
  canValidate: boolean;
  invalidEndDate: boolean;
  fields: any[];
  dealList: any[];
  contactList: any[];
  orgDropdownList: any[];
  dataList: any[];
  isLoading: boolean;
  activityType: any[];
  activityId: null;
  showActivityPopUp: boolean;
  activityStatus: any[];
  ownerList: any[];
  accountList: any[];
  buildingForm: boolean;
  customFields: any[];
}

class AddActivityPopup extends Component<
  IAddActivityPopupProps,
  IAddActivityPopupState
> {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
      buildingForm: true,
      invalidEndDate: false,
      fields: [],
      dealList:
        TableManger.getColumn(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.LINKED_TO)
          ?.options || [],
      contactList:
        TableManger.getColumn(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.CONTACT_IDS)
          ?.options || [],
      orgDropdownList:
        TableManger.getColumn(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
        )?.options || [],
      ownerList:
        TableManger.getColumn(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.OWNER_ID)
          ?.options || [],
      accountList:
        TableManger.getColumn(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.ACCOUNT_ID)
          ?.options || [],
      dataList: [],
      isLoading: true,
      activityType: [],
      activityId: null,
      showActivityPopUp: false,
      activityStatus: [],
      customFields: []
    };
  }
  componentDidMount() {
    this.getListData();
    // document.addEventListener("keydown", this.bindEnter);
  }
  componentWillUnmount() {
    // document.removeEventListener("keydown", this.bindEnter);
  }

  bindEnter = (evt) => {
    evt = evt || window.event;
    if (evt.keyCode === 13) {
      this.addActivity();
    }
  };
  addActivity = () => {
    this.setState({
      canValidate: true
    });
    if (this.isFormDataValid()) {
      this.onSubmit(this.state.fields);
    }
  };
  buildForm = async () => {
    let activityTypeData = [];
    let dataList = [];
    let activityType = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE
    );
    activityType.options.forEach((option) => {
      activityTypeData.push({ title: option.name, value: option.id });
    });
    activityTypeData = this.changeTypePosition(activityTypeData);
    dataList["activityType"] = activityTypeData;
    let statusOptions = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.STATUS
    );
    statusOptions.required = true;
    dataList["status"] = statusOptions.options.map((option) => option.name);
    let columns = TableManger.getTableColumns(TABLES.ACTIVITY);
    let filteredFields = this.getCommonFields(columns);

    ////////   Assign default owner (logged in user) if assign owner permission is not assigned ////////////////

    // const permServ = PermissionService.getInstance();
    // const modeulePerms = permServ.getUserPermissionByModule(permServ.getUpdatedModuleName(TABLES.ACTIVITY));
    // const OWNER_ID = TableManger.getColumnId(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.OWNER_ID);
    // filteredFields?.forEach(col => {
    //   if (!modeulePerms.includes(USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM)) {
    //     filteredFields = fields.filter(col => col.id !== OWNER_ID);
    //   }
    // })

    let customFields = DataGridUtility.getFormElements(
      columns.filter((col) => !col.columnCode)
    );

    this.setState({ fields: filteredFields, dataList, customFields }, () => {
      if (this.props.selectedActivity && this.props.selectedActivity.id) {
        this.setState({ activityId: this.props.selectedActivity.id });
        this.editFormBuild(this.props.selectedActivity);
      }
      this.setInitialDate();
      this.setState({ showActivityPopUp: true });
    });
    return Promise.resolve();
  };
  editFormBuild = (data) => {
    let defaultForm = [...this.state.fields];
    let customFieldsDefault = [...this.state.customFields];
    defaultForm.forEach((value, index) => {
      defaultForm[index].value = data[defaultForm[index].key];
    });
    let activityData = store.getState().records.data.activity.data;
    let activityCurrenctData = activityData.filter(
      (col) => col._id === this.props.selectedActivity?.id
    );
    customFieldsDefault.forEach((field, index) => {
      let findValue = data.customFieldData?.find((col) => {
        return field.key in col;
      });
      customFieldsDefault[index].value = Utility.isEmptyObject(findValue)
        ? null
        : findValue[field.key];
      if (!Utility.isEmptyObject(field.lookup)) {
        customFieldsDefault[index].value =
          activityCurrenctData[0].cells[field.id + "_detail"]?.[0] ?? null;
      }
    });
    ActivityManager.getDataToUpdate(customFieldsDefault);
    defaultForm = this.updateActivityType(defaultForm, true);
    defaultForm = this.updateActivityStatus(defaultForm, true);
    this.setState({
      fields: defaultForm,
      showActivityPopUp: true,
      customFields: customFieldsDefault
    });
  };
  changeTypePosition = (activityType) => {
    let index = activityType.findIndex((type) => type.title === "Task");
    if (index !== -1) {
      let firstPlace = activityType[index];
      activityType.splice(index, 1);
      activityType.splice(0, 0, firstPlace);
    }
    return activityType;
  };
  getCommonFields = (columns) => {
    let fields = [];
    columns.forEach((column) => {
      let value = null;
      if (column.columnCode === COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE) {
        value = 3;
        this.setState({ activityType: column.options });
      }
      if (
        column.columnCode === COLUMN_CODE.ACTIVITY.SEND_INVITATION ||
        column.columnCode === COLUMN_CODE.ACTIVITY.SEND_CONTACTS_INVITATION
      ) {
        value = false;
      }
      if (column.columnCode === COLUMN_CODE.ACTIVITY.STATUS) {
        value = [1];
        this.setState({ activityStatus: column.options });
      }
      if (
        column.columnCode === COLUMN_CODE.ACTIVITY.LINKED_TO &&
        this.props.module === "DEAL" &&
        this.props.moduleRecordId
      ) {
        value = [this.props.moduleRecordId];
      }
      if (
        column.columnCode === COLUMN_CODE.ACTIVITY.CONTACT_IDS &&
        this.props.module === "CONTACT" &&
        this.props.moduleRecordId
      ) {
        value = [this.props.moduleRecordId];
      }
      if (
        column.columnCode === COLUMN_CODE.ACTIVITY.ACCOUNT_ID &&
        this.props.accountId
      ) {
        value = [this.props.accountId];
      }
      if (
        column.columnCode === COLUMN_CODE.ACTIVITY.ACCOUNT_ID &&
        this.props.module === "ACCOUNT" &&
        this.props.moduleRecordId
      ) {
        value = [this.props.moduleRecordId];
      }
      if (column.columnCode === COLUMN_CODE.ACTIVITY.OWNER_ID) {
        value = [UserManager.getUserIamID()];
      }
      fields.push({
        id: column.id,
        key: column.columnCode,
        title: column.name,
        type: column.type,
        value: value,
        required: Utility.isEmptyObject(column.columnCode)
          ? false
          : column.required
            ? column.required
            : column?.requiredByUser ?? false
      });
    });
    return fields;
  };
  getListData = async () => {
    const deals = store.getState().records.data[TABLES.DEAL]?.data;
    if (deals?.length) {
      let dealList = ActivityManager.dealData(deals);
      dealList = dealList.filter(
        (deal) => !this.state.dealList.some((obj) => obj.id === deal.id)
      );
      if (
        this.props.module === "DEAL" &&
        this.props.moduleRecordId &&
        !this.state.dealList.some((obj) => obj.id === this.props.moduleRecordId)
      ) {
        let dealOption = dealList.find(
          (deal) => deal.id === this.props.moduleRecordId
        );
        dealOption &&
          this.updateDropdownOptions(
            dealOption,
            COLUMN_CODE.ACTIVITY.LINKED_TO
          );
      }
      this.setState((prevState) => ({
        dealList: [...prevState.dealList, ...dealList]
      }));
    }

    const contacts = store.getState().records.data[TABLES.CONTACT]?.data;
    if (contacts?.length) {
      let contactStoreList = ActivityManager.contactData(contacts);
      contactStoreList = contactStoreList.filter(
        (person) => !this.state.contactList.some((obj) => obj.id === person.id)
      );

      this.setState((prevState) => ({
        contactList: [...prevState.contactList, ...contactStoreList]
      }));
    }

    const accounts = store.getState().records.accounts.data;
    if (accounts?.length) {
      let accountList = ActivityManager.accountData(accounts);
      accountList = accountList.filter(
        (deal) => !this.state.accountList.some((obj) => obj.id === deal.id)
      );
      if (
        this.props.module === "ACCOUNT" &&
        this.props.moduleRecordId &&
        !this.state.accountList.some(
          (obj) => obj.id === this.props.moduleRecordId
        )
      ) {
        let accountOption = accountList.find(
          (account) => account.id === this.props.moduleRecordId
        );
        accountOption &&
          this.updateDropdownOptions(
            accountOption,
            COLUMN_CODE.ACTIVITY.ACCOUNT_ID
          );
      }
      this.setState((prevState) => ({
        accountList: [...prevState.accountList, ...accountList]
      }));
    }

    await this.buildForm();
    this.setState({ buildingForm: false });
  };

  getDealInputField = () => {
    const dealField = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.LINKED_TO
    );

    let accountData: any = ActivityManager.getFieldData(
      this.state.fields,
      COLUMN_CODE.ACTIVITY.ACCOUNT_ID
    );

    let dealList = this.state.dealList;

    if (!Utility.isEmptyObject(accountData)) {
      dealList = this.state.dealList.filter((deal) =>
        (deal?.accountId || [])?.includes(accountData?.[0])
      );
    }

    let dealDetailData = [];
    if (!Utility.isEmptyObject(this.props?.selectedActivity)) {
      dealDetailData = ActivityManager.dealData(
        this.props.selectedActivity[
          COLUMN_CODE.ACTIVITY.LINKED_TO + "_detail"
        ] ?? []
      );
    }

    return (
      <DKInput
        title="Linked Deal"
        type={INPUT_TYPE.DROPDOWN}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={
          dealField.required
            ? dealField.required
            : dealField?.requiredByUser ?? false
        }
        className="mt-l"
        value={ActivityManager.getFieldData(
          this.state.fields,
          COLUMN_CODE.ACTIVITY.LINKED_TO
        )}
        canValidate={this.state.canValidate}
        onChange={(value) => {}}
        renderer={(value) => {
          let dealName = ActivityManager.getFieldData(
            this.state.fields,
            COLUMN_CODE.ACTIVITY.LINKED_TO,
            "name"
          );
          if (!dealName && Array.isArray(value)) {
            dealName =
              dealList.find((deal) => deal.id === value[0])?.name ??
              dealDetailData?.[0]?.name;
          }
          return dealName ? (
            <div
              className="row width-auto bg-gray1 border-radius-s p-xs border-m mr-r"
              style={{ gap: 4 }}
            >
              <DKLabel className="" text={dealName || "-"} />
              {!Utility.isEmptyObject(value) && (
                <DKIcon
                  src={DKIcons.ic_close_}
                  className={"ic-s-2 ml-s unselectable cursor-hand"}
                  style={{ height: 10, opacity: 0.5, padding: 1 }}
                  onClick={() => {
                    this.updateDropdownOptions(
                      {},
                      COLUMN_CODE.ACTIVITY.LINKED_TO
                    );
                  }}
                />
              )}
            </div>
          ) : null;
        }}
        dropdownConfig={{
          title: "Select Deal",
          allowSearch: true,
          searchableKey: "name",
          style: { minWidth: 230 },
          className: "shadow-m",
          searchApiConfig: {
            method: "POST",
            getUrl: (val) => {
              return (
                ApiConstants.URL.BASE +
                ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                  TableManger.getTableId(TABLES.DEAL)
                ) +
                "?pageNo=1&pageSize=20"
              );
            },
            getPayload: (search) => {
              return {
                logicalOperator: "and",
                conditions: [
                  {
                    colId: TableManger.getColumnId(
                      TABLES.DEAL,
                      COLUMN_CODE.DEAL.NAME
                    ),
                    opr: "c",
                    value: search
                  }
                ]
              };
            },
            dataParser: (response) => {
              let personList = ActivityManager.dealData(response.data);
              if (!Utility.isEmptyObject(accountData)) {
                personList = personList.filter((deal) =>
                  deal.accountId.includes(accountData?.[0])
                );
              }
              return personList;
            }
          },
          data: dealList,
          renderer: (index, obj) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index, obj, rowIndex) => {
            this.updateDropdownOptions(obj, COLUMN_CODE.ACTIVITY.LINKED_TO);

            let filterAccount = this.state.accountList?.filter(
              (account) => account.id === obj.accountId?.[0]
            );
            if (filterAccount?.length > 0) {
              this.updateDropdownOptions(
                filterAccount?.[0] ?? [],
                COLUMN_CODE.ACTIVITY.ACCOUNT_ID
              );
            } else {
              this.saveFormData(COLUMN_CODE.ACTIVITY.ACCOUNT_ID, null);
            }
          }
        }}
      />
    );
  };

  getContactInputField = () => {
    const contactField = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.CONTACT_IDS
    );

    let accountData: any = ActivityManager.getFieldData(
      this.state.fields,
      COLUMN_CODE.ACTIVITY.ACCOUNT_ID
    );

    let contactList = this.state.contactList;

    if (!Utility.isEmptyObject(accountData)) {
      contactList = this.state.contactList.filter((contact) =>
        contact.accountId?.includes(accountData?.[0])
      );
    }

    let contactDetailData = [];
    if (!Utility.isEmptyObject(this.props?.selectedActivity)) {
      contactDetailData = ActivityManager.contactData(
        this.props.selectedActivity[
          COLUMN_CODE.ACTIVITY.CONTACT_IDS + "_detail"
        ] ?? []
      );
    }

    return (
      <DKInput
        title="Linked Contact"
        type={INPUT_TYPE.DROPDOWN}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={
          contactField.required
            ? contactField.required
            : contactField?.requiredByUser ?? false
        }
        className="mt-m"
        renderer={(value) => {
          let contactName = ActivityManager.getFieldData(
            this.state.fields,
            COLUMN_CODE.ACTIVITY.CONTACT_IDS,
            "name"
          );

          if (!contactName && Array.isArray(value)) {
            contactName =
              contactList.find((contact) => contact.id === value[0])?.name ??
              contactDetailData?.[0]?.name;
          }
          return contactName ? (
            <div
              className="row width-auto bg-gray1 border-radius-s p-xs border-m mr-r"
              style={{ gap: 4 }}
            >
              <DKLabel className="" text={contactName || "-"} />
              {!Utility.isEmptyObject(value) && (
                <DKIcon
                  src={DKIcons.ic_close_}
                  className={"ic-s-2 ml-s unselectable cursor-hand"}
                  style={{ height: 10, opacity: 0.5, padding: 1 }}
                  onClick={() => {
                    this.updateDropdownOptions(
                      {},
                      COLUMN_CODE.ACTIVITY.CONTACT_IDS
                    );
                  }}
                />
              )}
            </div>
          ) : null;
        }}
        value={ActivityManager.getFieldData(
          this.state.fields,
          COLUMN_CODE.ACTIVITY.CONTACT_IDS
        )}
        canValidate={this.state.canValidate}
        onChange={(value) => {}}
        dropdownConfig={{
          title: "Select Contact",
          allowSearch: true,
          searchableKey: "name",
          style: { minWidth: 230 },
          className: "shadow-m",
          searchApiConfig: {
            method: "POST",
            getUrl: (val) => {
              return (
                ApiConstants.URL.BASE +
                ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                  TableManger.getTableId(TABLES.CONTACT)
                ) +
                "?pageNo=1&pageSize=20"
              );
            },
            getPayload: (search) => {
              return Utility.isEmptyObject(search)
                ? {}
                : {
                    logicalOperator: "and",
                    conditions: [
                      {
                        colId: TableManger.getColumnId(
                          TABLES.CONTACT,
                          COLUMN_CODE.CONTACT.NAME
                        ),
                        opr: "c",
                        value: search
                      }
                    ]
                  };
            },
            dataParser: (response) => {
              let personList = ActivityManager.contactData(response.data);
              if (!Utility.isEmptyObject(accountData)) {
                personList = personList.filter((contact) =>
                  contact.accountId.includes(accountData?.[0])
                );
              }

              return personList;
            }
          },
          data: contactList,
          renderer: (index, obj) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index, obj, rowIndex) => {
            this.updateDropdownOptions(obj, COLUMN_CODE.ACTIVITY.CONTACT_IDS);

            let filterAccount = this.state.accountList?.filter(
              (account) => account.id === obj.accountId?.[0]
            );
            if (filterAccount?.length > 0) {
              this.updateDropdownOptions(
                filterAccount?.[0] ?? [],
                COLUMN_CODE.ACTIVITY.ACCOUNT_ID
              );
            } else {
              this.saveFormData(COLUMN_CODE.ACTIVITY.ACCOUNT_ID, null);
            }
          }
        }}
      />
    );
  };

  getAccountInputField = () => {
    const accountField = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.ACCOUNT_ID
    );

    let contactValue = ActivityManager.getFieldData(
      this.state.fields,
      COLUMN_CODE.ACTIVITY.CONTACT_IDS
    );

    let dealValue = ActivityManager.getFieldData(
      this.state.fields,
      COLUMN_CODE.ACTIVITY.LINKED_TO
    );

    return (
      <DKInput
        title="Linked Account"
        type={INPUT_TYPE.DROPDOWN}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={
          accountField.required
            ? accountField.required
            : accountField?.requiredByUser ?? false
        }
        className="mt-l"
        renderer={(value) => {
          let accountName = ActivityManager.getFieldData(
            this.state.fields,
            COLUMN_CODE.ACTIVITY.ACCOUNT_ID,
            "name"
          );
          if (!accountName && Array.isArray(value)) {
            accountName = this.state.accountList.find(
              (account) => account.id === value[0]
            )?.name;
          }
          return accountName ? (
            <div
              className="row width-auto bg-gray1 border-radius-s p-xs border-m mr-r"
              style={{ gap: 4 }}
            >
              <DKLabel className="" text={accountName || "-"} />
              {!Utility.isEmptyObject(value) && (
                <DKIcon
                  src={DKIcons.ic_close_}
                  className={"ic-s-2 ml-s unselectable cursor-hand"}
                  style={{ height: 10, opacity: 0.5, padding: 1 }}
                  onClick={() => {
                    if (
                      !Utility.isEmptyObject(contactValue) ||
                      !Utility.isEmptyObject(dealValue)
                    ) {
                      this.showAlertForAccountChanged(
                        {},
                        contactValue,
                        dealValue
                      );
                      return;
                    }

                    this.updateDropdownOptions(
                      {},
                      COLUMN_CODE.ACTIVITY.ACCOUNT_ID
                    );
                  }}
                />
              )}
            </div>
          ) : null;
        }}
        value={ActivityManager.getFieldData(
          this.state.fields,
          COLUMN_CODE.ACTIVITY.ACCOUNT_ID
        )}
        canValidate={this.state.canValidate}
        onChange={(value) => {}}
        dropdownConfig={{
          title: "Select Account",
          allowSearch: true,
          searchableKey: "name",
          style: { minWidth: 230 },
          className: "shadow-m",
          searchApiConfig: {
            method: "POST",
            getUrl: (val) => {
              return (
                ApiConstants.URL.BASE +
                ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                  TableManger.getTableId(TABLES.ACCOUNT)
                ) +
                "?pageNo=1&pageSize=20"
              );
            },
            getPayload: (search) => {
              return Utility.isEmptyObject(search)
                ? {}
                : {
                    logicalOperator: "and",
                    conditions: [
                      {
                        colId: TableManger.getColumnId(
                          TABLES.ACCOUNT,
                          COLUMN_CODE.ACCOUNT.NAME
                        ),
                        opr: "c",
                        value: search
                      }
                    ]
                  };
            },
            dataParser: (response) => {
              let accountList = ActivityManager.accountData(response.data);
              return accountList;
            }
          },
          data: [],
          renderer: (index, obj) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index, obj, rowIndex) => {
            if (
              !Utility.isEmptyObject(contactValue) ||
              !Utility.isEmptyObject(dealValue)
            ) {
              this.showAlertForAccountChanged(obj, contactValue, dealValue);
              return;
            }
            this.updateDropdownOptions(obj, COLUMN_CODE.ACTIVITY.ACCOUNT_ID);
          }
        }}
      />
    );
  };

  showAlertForAccountChanged = (obj = {}, contactValue, dealValue) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m",
        onClick: () => {}
      },
      {
        title: "Remove Account",
        className: "bg-red text-white ml-r",
        onClick: () => {
          this.updateDropdownOptions(obj, COLUMN_CODE.ACTIVITY.ACCOUNT_ID);
          setTimeout(() => {
            this.saveFormData(COLUMN_CODE.ACTIVITY.LINKED_TO, null);
            this.saveFormData(COLUMN_CODE.ACTIVITY.CONTACT_IDS, null);
          }, 100);
        }
      }
    ];

    let errorLinkedData = [];
    if (!Utility.isEmptyObject(contactValue)) {
      errorLinkedData.push("contact");
    }
    if (!Utility.isEmptyObject(dealValue)) {
      errorLinkedData.push("deal");
    }

    showAlert(
      "Warning! ",
      `Changing this account will remove linked ${errorLinkedData.toString()?.replaceAll(",", " and ")}. Do you want to proceed?`,
      buttons
    );
  };

  render() {
    const allowActivityFieldCreate =
      PermissionService.getInstance().isUserPermitted(TABLES.ACTIVITY, [
        USER_ACTION_TYPES.FIELD_CREATE
      ]);
    const displayOwnerField = PermissionService.getInstance().isUserPermitted(
      TABLES.ACTIVITY,
      [USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM]
    );
    const ownerField = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.OWNER_ID
    );

    const noteField = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.NOTES
    );
    const statusField = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.STATUS
    );
    return (
      <div className="transparent-background add-activity-popup">
        <div className="border-radius-s bg-white shadow-m p-l popup-window">
          {this.state.buildingForm && (
            <DKSpinner title="Loading form fields..." />
          )}
          {this.state.showActivityPopUp && (
            <>
              <div className="row justify-content-between ">
                <DKLabel
                  text={this.props.recordId ? "Edit Activity" : "Add Activity"}
                  className="fw-m fs-l"
                />
                {this.getActionButtons()}
              </div>
              <div className="parent-width mt-s">
                <DKInput
                  title="Name"
                  autoFocus
                  type={INPUT_TYPE.TEXT}
                  value={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.NAME
                  )}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required
                  canValidate={this.state.canValidate}
                  onChange={(value) =>
                    this.saveFormData(COLUMN_CODE.ACTIVITY.NAME, value)
                  }
                />
                <DKLabel text="Activity Type *" className=" mt-l mb-s" />
                <SegmentControl
                  className="text-blue justify-content-start row-responsive overflow-y-auto "
                  data={this.state.dataList?.["activityType"]}
                  onChange={(value) =>
                    this.saveFormData(COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE, value)
                  }
                  value={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE
                  )}
                />

                <DKInputDateTime
                  title={`Start Date *`}
                  selectedDate={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.START_DATE
                  )}
                  onSave={(date) =>
                    this.saveFormData(COLUMN_CODE.ACTIVITY.START_DATE, date)
                  }
                />

                <DKInputDateTime
                  title={`End Date *`}
                  selectedDate={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.END_DATE
                  )}
                  onSave={(date) =>
                    this.saveFormData(COLUMN_CODE.ACTIVITY.END_DATE, date)
                  }
                />
                {this.state.invalidEndDate && (
                  <div
                    className="fs-r text-align-left text-red mt-s ml-xs"
                    style={{ fontSize: "11px" }}
                  >
                    End date should be greater than start date
                  </div>
                )}
                <DKInput
                  title="Owner"
                  type={INPUT_TYPE.DROPDOWN}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={
                    ownerField.required
                      ? ownerField.required
                      : ownerField?.requiredByUser ?? false
                  }
                  className="mt-m"
                  readOnly={displayOwnerField ? false : true}
                  formatter={(obj) => {
                    let ownerName = ActivityManager.getFieldData(
                      this.state.fields,
                      COLUMN_CODE.ACTIVITY.OWNER_ID,
                      "name"
                    );
                    if (!ownerName && Array.isArray(obj)) {
                      ownerName = this.state.ownerList.find(
                        (owner) => owner.id === obj[0]
                      )?.name;
                    }
                    return ownerName;
                  }}
                  value={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.OWNER_ID
                  )}
                  canValidate={this.state.canValidate}
                  onChange={(value) => {}}
                  dropdownConfig={{
                    title: "Select Owner",
                    allowSearch: true,
                    searchableKey: "name",
                    style: { minWidth: 230 },
                    className: "shadow-m",
                    data: this.state.ownerList,
                    renderer: (index, obj) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index, obj, rowIndex) => {
                      this.updateDropdownOptions(
                        obj,
                        COLUMN_CODE.ACTIVITY.OWNER_ID
                      );
                    }
                  }}
                />

                {this.getAccountInputField()}

                {this.getContactInputField()}

                {this.getDealInputField()}

                <DKInput
                  title="Status"
                  type={INPUT_TYPE.SELECT}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  options={this.state.dataList["status"]}
                  required={
                    statusField.required
                      ? statusField.required
                      : statusField?.requiredByUser ?? false
                  }
                  className="mt-l"
                  value={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.STATUS
                  )}
                  canValidate={this.state.canValidate}
                  onChange={(value) =>
                    this.saveFormData(COLUMN_CODE.ACTIVITY.STATUS, value)
                  }
                />
                <DKInput
                  title="Notes"
                  type={INPUT_TYPE.LONG_TEXT}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={
                    noteField.required
                      ? noteField.required
                      : noteField?.requiredByUser ?? false
                  }
                  className="mt-m mb-s"
                  value={ActivityManager.getFieldData(
                    this.state.fields,
                    COLUMN_CODE.ACTIVITY.NOTES
                  )}
                  canValidate={this.state.canValidate}
                  onChange={(value) =>
                    this.saveFormData(COLUMN_CODE.ACTIVITY.NOTES, value)
                  }
                />
              </div>

              {this.getFields()}

              {allowActivityFieldCreate && (
                <div className="row row-reverse mt-m">
                  <DKButton
                    className="bg-gray1 border-m"
                    title={"New Field"}
                    icon={DKIcons.ic_add}
                    onClick={this.onCustomFieldAdd}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
  onCustomFieldAdd = () => {
    showAddColumnPopup({ tableName: TABLES.ACTIVITY }, () => {
      let columns = TableManger.getTableColumns(TABLES.ACTIVITY);
      let customFields = DataGridUtility.getFormElements(
        columns.filter((col) => !col.columnCode)
      );
      let customFieldData = [...this.state.customFields];
      let ids = new Set(customFieldData.map((d) => d.id));
      let newCustomField = [
        ...customFieldData,
        ...customFields.filter((d) => !ids.has(d.id))
      ];
      this.setState({ customFields: newCustomField });
    });
  };
  getActionButtons() {
    return (
      <div>
        <div className="row">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => {
              this.removePopUp();
            }}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-r"
            onClick={() => {
              this.setState({
                canValidate: true
              });
              if (this.isFormDataValid()) {
                this.onSubmit(this.state.fields);
              }
            }}
          />
        </div>
      </div>
    );
  }

  lookupFormatter = (obj) => {
    return obj ? obj?.name || obj.value : "";
  };

  getErrorMessage = (fieldName: string, isRequired: boolean) => {
    if (isRequired) {
      return `${fieldName} is required and cannot exceed limit ${TEXT_AREA_DEFAULT_LENGTH}`;
    } else {
      return `${fieldName} cannot exceed limit ${TEXT_AREA_DEFAULT_LENGTH}`;
    }
  };

  getFields() {
    return this.state.customFields?.map((obj, index) => {
      let type =
        obj.type === INPUT_TYPE_TEXT_AREA ? INPUT_TYPE.LONG_TEXT : obj.type;
      let validator = INPUT_TYPE_TEXT_AREA
        ? (value) => {
            return (
              !Utility.isEmptyObject(value) &&
              value?.length < TEXT_AREA_DEFAULT_LENGTH
            );
          }
        : obj.validator;

      let errorMessage = "";

      if (obj.type === INPUT_TYPE_TEXT_AREA) {
        errorMessage = this.getErrorMessage(
          obj.title,
          obj.required ? obj.required : obj?.requiredByUser ?? false
        );
      }

      return (
        <DKInput
          autoFocus={false}
          className={"p-v-s"}
          icon={obj.icon}
          title={obj.title}
          placeholder={obj.placeholder}
          value={
            obj.type === "date" && !Utility.isEmptyObject(obj.value)
              ? new Date(obj.value)
              : obj.value
          }
          options={obj.options}
          type={type}
          validator={validator}
          errorMessage={errorMessage}
          canValidate={this.state.canValidate}
          tooltip={obj.tooltip}
          dropdownConfig={obj.dropdownConfig}
          formatter={
            !Utility.isEmptyObject(obj.lookup)
              ? this.lookupFormatter
              : obj.formatter
          }
          onChange={(value) => {
            let data = this.state.customFields[index];
            if (obj.type === INPUT_TYPE.DATE) {
              value = DateUtil.getDateWithCurrentTime(value);
            }
            data["value"] = value;
          }}
          dateFormat={convertCRMDateFormatToUILibraryFormat(
            DateUtil.getOrgDateFormat()
          )}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={obj.required}
          datePickerConfig={obj.datePickerConfig}
          readOnly={false}
          onClick={() => {}}
        />
      );
    });
  }

  onSubmit = (data) => {
    if (
      Utility.isEmptyObject(data) ||
      !ActivityManager.validateCustomFields(this.state.customFields)
    ) {
      return;
    }

    data = this.updateActivityType(data);
    data = this.updateActivityStatus(data);
    let request = ActivityManager.createRequestJson(data);

    if (this.state.customFields.length > 0) {
      request = ActivityManager.updateReqAsCustomFieldData(
        request,
        this.state.customFields
      );
    }

    if (Utility.isEmptyObject(this.props.recordId)) {
      this.onAdd(request);
    } else {
      this.onUpdate(request);
    }
  };
  onAdd = (request) => {
    const recId = getRandomHexString();
    Table.addRecord(
      { _id: recId, cells: request },
      ActivityManager.getTableId()
    )
      .then((response) => {
        if (this.props.module !== TABLES.ACCOUNT) this.getActivity();
        if (response && this.props.onSubmit) this.props.onSubmit();
      })
      .catch((err) => {
        store.dispatch(
          deleteRecord({
            tableName: TABLES.ACTIVITY,
            recIds: [recId]
          })
        );
      });

    store.dispatch(
      addRecord({
        tableName: TABLES.ACTIVITY,
        record: { _id: recId, cells: request }
      })
    );
    this.removePopUp();
  };
  onUpdate = (request) => {
    store.dispatch(
      updateRecord({
        tableName: TABLES.ACTIVITY,
        recId: this.props.selectedActivity.id,
        record: request
      })
    );
    this.removePopUp();
    Table.updateRecord(
      request,
      this.state.activityId,
      ActivityManager.getTableId()
    ).then((response) => {
      if (this.props.module !== TABLES.ACCOUNT) this.getActivity();
      if (this.props.onSubmit) this.props.onSubmit();
    });
  };

  updateDropdownOptions = (obj, colCode) => {
    const dropdownColData = TableManger.getColumn(TABLES.ACTIVITY, colCode);
    const dropdownColumnOptions = dropdownColData?.options || [];
    if (
      dropdownColumnOptions.findIndex((option) => option.id === obj.id) === -1
    ) {
      const options = [...dropdownColumnOptions, obj];
      TableManger.updateColumn(colCode, TABLES.ACTIVITY, {
        ...dropdownColData,
        type: INPUT_TYPE.SELECT,
        options: options
      });
    }
    this.saveFormData(colCode, obj);
  };

  updateActivityType = (data, isEdit = false) => {
    data.forEach((field) => {
      if (field.key === COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE) {
        if (isEdit) {
          let id = this.state.activityType.find(
            (f) => f.id === field.value[0]
          )?.id;
          field.value = id;
        } else {
          field.value = [
            this.state.activityType.find((type) => type.id === field.value)?.id
          ];
        }
      }
    });
    return data;
  };

  updateActivityStatus = (data, isEdit = false) => {
    data.forEach((field) => {
      if (field.key === COLUMN_CODE.ACTIVITY.STATUS) {
        if (isEdit) {
          let id = this.state.activityStatus.findIndex(
            (f) => f.id === field.value[0]
          );
          field.value = [id];
        } else {
          field.value = [this.state.activityStatus[field.value[0]]?.id];
        }
      }
    });
    return data;
  };

  saveFormData = (key, value) => {
    let fields = [...this.state.fields];
    fields.forEach((field) => {
      if (field.key === key) {
        field.value = value;
        if (
          key === COLUMN_CODE.ACTIVITY.SEND_INVITATION ||
          key === COLUMN_CODE.ACTIVITY.SEND_CONTACTS_INVITATION
        ) {
          field.value = !field.value;
        }

        if (
          key === COLUMN_CODE.ACTIVITY.CONTACT_IDS ||
          key === COLUMN_CODE.ACTIVITY.LINKED_TO ||
          key === COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS ||
          key === COLUMN_CODE.ACTIVITY.OWNER_ID ||
          key === COLUMN_CODE.ACTIVITY.ACCOUNT_ID
        ) {
          field.value = value?.id ? [value.id] : null;
          field.name = value?.name ?? "";
        }
      }
    });

    this.setState({ fields }, () => this.validateActivityDates);
  };
  isFormDataValid() {
    let valid = true;
    let startDate;
    let endDate;
    this.state.fields.forEach((field) => {
      if (field.required !== false) {
        if (
          field.value === null ||
          field.value === undefined ||
          field.value === ""
        ) {
          valid = false;
        } else if (
          typeof field.value === "string" &&
          field.value.trim() === ""
        ) {
          valid = false;
        } else if (
          field.type === INPUT_TYPE.EMAIL &&
          !Utility.isValidEmail(field.value)
        ) {
          valid = false;
        } else if (field.type === INPUT_TYPE.NUMBER && isNaN(field.value)) {
          valid = false;
        } else if (
          (field.type === INPUT_TYPE.SELECT ||
            field.type === INPUT_TYPE.MULTI_SELECT) &&
          field.value.length === 0
        ) {
          valid = false;
        } else if (field.type === INPUT_TYPE.DATE) {
          if (field.key === "start_date") {
            startDate = field.value;
          }
          if (field.key === "end_date") {
            endDate = field.value;
          }
          if (startDate > endDate) {
            valid = false;
            this.setState({ invalidEndDate: true });
          } else {
            this.setState({ invalidEndDate: false });
          }
        }
      }
    });
    return valid;
  }
  setInitialDate = () => {
    let startDate = ActivityManager.getFieldData(
      this.state.fields,
      COLUMN_CODE.ACTIVITY.START_DATE
    );
    if (!startDate && !this.props.startDate) {
      this.saveFormData(
        COLUMN_CODE.ACTIVITY.START_DATE,
        new Date().toISOString()
      );
    } else {
      this.saveFormData(COLUMN_CODE.ACTIVITY.START_DATE, this.props.startDate);
    }
    let endDate = ActivityManager.getFieldData(
      this.state.fields,
      COLUMN_CODE.ACTIVITY.END_DATE
    );
    if (!endDate && !this.props.endDate) {
      this.saveFormData(
        COLUMN_CODE.ACTIVITY.END_DATE,
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      );
    } else {
      this.saveFormData(COLUMN_CODE.ACTIVITY.END_DATE, this.props.endDate);
    }
    this.setState({ isLoading: false });
  };
  validateActivityDates = () => {
    let { fields } = this.state;
    const startDate = fields.find(
      (field) => field.key === COLUMN_CODE.ACTIVITY.START_DATE
    ).value;
    const endDate = fields.find(
      (field) => field.key === COLUMN_CODE.ACTIVITY.END_DATE
    ).value;
    const isStartDateValid =
      new Date(startDate).getTime() >= new Date().getTime();
    const isEndDateValid =
      new Date(endDate).getTime() >= new Date().getTime() &&
      new Date(endDate).getTime() > new Date(startDate).getTime();
    if (isStartDateValid && isEndDateValid) {
      this.setState({ invalidEndDate: false });
    }
  };
  onCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
    this.removePopUp();
  };
  removePopUp = () => {
    if (this.props.popupId) {
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
      document.getElementById(this.props.popupId)?.remove();
    }
  };
  getLinkedModuleFilterCondition = () => {
    const filterConditions = [];
    if (this.props.moduleRecordId && this.props.module === "DEAL") {
      filterConditions.push({
        key: TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.LINKED_TO
        ),
        condition: "in",
        value: this.props.moduleRecordId,
        isExternal: true
      });
    }

    if (this.props.moduleRecordId && this.props.module === "CONTACT") {
      filterConditions.push({
        key: TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.CONTACT_IDS
        ),
        condition: "in",
        value: this.props.moduleRecordId,
        isExternal: true
      });
    }

    if (this.props.moduleRecordId && this.props.module === "ACCOUNT") {
      filterConditions.push({
        key: TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.ACCOUNT_ID
        ),
        condition: "in",
        value: this.props.moduleRecordId,
        isExternal: true
      });
    }

    return filterConditions;
  };
  getActivity = () => {
    let req = { pageNo: 1, pageSize: 500, fetchAllRef: true, q: "" };
    const activityTableMetaData =
      store.getState().tableMetaData[TABLES.ACTIVITY];
    let searchText = activityTableMetaData?.searchQuery;
    if (
      Utility.isEmptyObject(this.props.moduleRecordId) &&
      !Utility.isEmptyObject(searchText)
    ) {
      req.q = searchText;
    }
    let conditions = Utility.isEmptyObject(this.props.moduleRecordId)
      ? activityTableMetaData?.filter?.conditions
      : this.getLinkedModuleFilterCondition();

    let payload = TableManger.getFilterObject(
      conditions,
      activityTableMetaData?.filter?.logicalOperator
    );

    if (!Utility.isEmptyObject(payload["conditions"])) {
      payload = TableDataParser.getModifiedFilterPayload(
        payload,
        ActivityManager.getFilterableColumns()
      );
    }
    if (!this.props.isFetchCalled) {
      store.dispatch(
        fetchRecordsByTable({
          tableName: TABLES.ACTIVITY,
          params: req,
          payload: { ...payload }
        })
      );
    }
  };
}
export const showAddActivityPopup = (config: {
  tableName?: string;
  selectedActivity?: any;
  module?: any;
  recordId?: any;
  moduleRecordId?: any;
  isEdit?: boolean;
  startDate?: any;
  endDate?: any;
  onSubmit?: any;
  isFetchCalled?: boolean;
  accountId?: any;
}) => {
  const id = `add-actvivity-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <AddActivityPopup
      isEdit={config.isEdit}
      selectedActivity={config.selectedActivity}
      module={config.module}
      popupId={id}
      recordId={config.recordId}
      accountId={config.accountId}
      moduleRecordId={config.moduleRecordId}
      onSubmit={config.onSubmit}
      startDate={config.startDate}
      endDate={config.endDate}
      isFetchCalled={config.isFetchCalled || false}
    />,
    document.body.appendChild(div)
  );
};
export default AddActivityPopup;
