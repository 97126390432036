import React from "react";
import ReactDOM from "react-dom";
import Popup from "./Popup";
import {
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKCheckMark,
  DKLabel,
  DKButton,
  isEmpty,
  showLoader,
  removeLoader,
  DKIcons,
  DKIcon,
  getDataTypeIcon,
  TOAST_TYPE,
  showToast
} from "deskera-ui-library";
import Table from "../../services/table";
import { TableManger, TABLES } from "../../managers/TableManger";
import { IColumn } from "../../model/Table";
import InitialApiManager from "../../managers/InitialApiManager";
import TableDataParser from "../../Helper/TableDataParser";
import ControlledInput from "../common/ControlledInput";
import {
  BOOKS_CUSTOM_FIELD_TYPE,
  CHAR_CODES,
  INPUT_TYPE_TEXT_AREA
} from "../../constants/Constant";
import Utility, {
  getCapitalized,
  getRandomNumber
} from "../../utility/Utility";

export interface IAddColumnPopupProps {
  tableName: string;
  customFieldData?: any;
  popupId?: string;
  onSave?: (response?: any) => void;
  onClose?: () => void;
}
export interface IAddColumnPopupState {
  formData: any;
  optionFieldText: string;
  submitted: boolean;
  parentCustomField: any;
  canValidate: boolean;
}
class AddColumnPopup extends React.Component<
  IAddColumnPopupProps,
  IAddColumnPopupState
> {
  isEditMode: boolean;
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: "",
        type: INPUT_TYPE.TEXT,
        required: false,
        options: []
      },
      optionFieldText: "",
      submitted: false,
      parentCustomField: null,
      canValidate: false,
    };
    this.isEditMode = false;
  }

  componentDidMount() {
    if (!Utility.isEmptyObject(this.props.customFieldData)) {
      this.populateForm();
      this.isEditMode = !Utility.isEmptyObject(this.props.customFieldData?.id);
    }
  }

  populateForm = () => {
    const customFieldData = this.props.customFieldData;
    let fieldType = customFieldData.fieldType;
    let newConfigOptions = {};
    let options: any[] = [];

    if (fieldType === BOOKS_CUSTOM_FIELD_TYPE.DATE) {
      newConfigOptions = {
        defaultValue: customFieldData.defaultValue
      };
      fieldType = INPUT_TYPE.DATE;
      options = [];
    }

    if (fieldType === BOOKS_CUSTOM_FIELD_TYPE.TEXT) {
      newConfigOptions = {
        maxLength: this.props.customFieldData?.maxLength,
        defaultValue: customFieldData.defaultValue
      };
      fieldType = INPUT_TYPE.TEXT;
      options = [];
    }

    if (fieldType === BOOKS_CUSTOM_FIELD_TYPE.NUMBER) {
      newConfigOptions = {
        decimalPrecision: customFieldData.decimalPrecision,
        defaultValue: customFieldData.defaultValue
      };
      fieldType = INPUT_TYPE.NUMBER;
      options = [];
    }

    if (fieldType === BOOKS_CUSTOM_FIELD_TYPE.USER) {
      newConfigOptions = {
        maxLength: 100,
        defaultValue: ""
      };
      fieldType = INPUT_TYPE.USER;
      options = [];
    }

    if (fieldType === BOOKS_CUSTOM_FIELD_TYPE.TEXT_AREA) {
      newConfigOptions = {
        maxLength: 250,
        defaultValue: ""
      };

      fieldType = INPUT_TYPE_TEXT_AREA;
    }

    if (fieldType === BOOKS_CUSTOM_FIELD_TYPE.DROPDOWN) {
      newConfigOptions = {
        defaultValue: customFieldData.defaultValue
      };
      fieldType = INPUT_TYPE.SELECT;

      if (
        customFieldData?.attributes &&
        customFieldData.attributes.length > 0
      ) {
        options = customFieldData.attributes.map((attribute: any) => {
          let indexList: number[] = [];
          if (attribute?.parentIdList && attribute.parentIdList.length > 0) {
            attribute?.parentIdList?.forEach((parentId: any) => {
              customFieldData?.parent?.attributes?.forEach(
                (obj: any, index: number) => {
                  if (obj.id.toString() === parentId) {
                    indexList.push(index);
                  }
                }
              );
            });
          }

          if (indexList.length > 0) {
            return {
              id: attribute.id,
              name: attribute.value,
              selectedInd: indexList,
              defaultOption: attribute.defaultOption,
              status: attribute.status
            };
          }

          return {
            id: attribute.id,
            name: attribute.value,
            defaultOption: attribute.defaultOption,
            status: attribute.status,
            color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`
          };
        });

        if (customFieldData.parent) {
          this.setState({
            parentCustomField: customFieldData.parent
          });
        }
      }
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        name: customFieldData.label,
        type: fieldType,
        required: this.props.customFieldData?.mandatory,
        options: options
      }
    }));
  };

  onOptionChange = (value) => {
    this.setState({
      optionFieldText: value
    });
  };
  onOptionKeyPress = (e) => {
    if (e.charCode === CHAR_CODES.ENTER) {
      // on enter pressed
      this.onOptionAdd();
    }
  };
  onOptionAdd = () => {
    this.setState({ canValidate: true });
    if (isEmpty(this.state.optionFieldText)) return;
    let options = [...this.state.formData.options];
    let { optionFieldText } = this.state;
    const newID = parseInt(
      getRandomNumber(1000) + "" + this.state.formData.options.length
    );
    options.push({
      id: newID,
      name: optionFieldText,
      color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`
    });
    this.onFormValueChange("options", options);
    this.setState({
      optionFieldText: "",
      canValidate: false,
    });
  };
  onFormValueChange = (key, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  };
  onSave = () => {
    this.setState({
      submitted: true
    });
    if (this.state.formData.name.trim().length === 0) return;
    if (
      (this.state.formData.type === INPUT_TYPE.SELECT ||
        this.state.formData.type === INPUT_TYPE.MULTI_SELECT) &&
      this.state.formData.options.length === 0
    ) {
      showToast("Please add at least one option", TOAST_TYPE.FAILURE);
      return;
    }
    let column: IColumn = TableDataParser.getColumnToAdd({
      ...this.state.formData
    });
    /**
     * @description - only core API table will be handled here rest will be in parent component
     */
    if (this.props?.tableName !== TABLES.TICKET) {
      this.onAddColumn(column);
    } else {
      this.props.onSave({ columnData: column });
      this.removePopUp();
    }
  };
  onCancel = () => {
    this.removePopUp();
  };
  removePopUp = () => {
    if (this.props.popupId)
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
    document.getElementById(this.props.popupId)?.remove();
    if (this.props.onClose) this.props.onClose();
  };
  onAddColumn = (column) => {
    showLoader(`Custom field is being added...`);
    const sanitizedData = TableDataParser.getSanitizedJsonData(column);
    Table.addColumn(sanitizedData, TableManger.getTableId(this.props.tableName))
      .then((response) => {
        InitialApiManager.getTableData().then((data) => {
          if (this.props.onSave)
            this.props.onSave({ data: response, columnData: column });
        });
        this.removePopUp();
      })
      .catch((err) => {})
      .finally(() => removeLoader());
  };
  render() {
    return (
      <Popup>
        {this.getHeader()}
        {this.getForm()}
      </Popup>
    );
  }
  getHeader = () => {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <div className="row fs-xl fw-h">
          {this.props?.customFieldData?.id ? "Edit" : "New"} Field
        </div>
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            onClick={this.onSave}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={this.onCancel}
          />
        </div>
      </div>
    );
  };
  onOptionRemove = (index) => {
    let options = [...this.state.formData.options];
    options.splice(index, 1);
    this.onFormValueChange("options", options);
  };
  getForm = () => {
    return (
      <>
        <div className="row mt-l">
          <ControlledInput
            required
            invalid={
              this.state.submitted &&
              this.state.formData.name.trim().length === 0
            }
            name="Name"
            type={INPUT_TYPE.TEXT}
            value={this.state.formData.name}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(e) => this.onFormValueChange("name", e.target.value)}
          />
        </div>
        <div className="row mt-l">{this.getFieldTypeSelection()}</div>
        {(this.state.formData.type === INPUT_TYPE.SELECT ||
          this.state.formData.type === INPUT_TYPE.MULTI_SELECT) && (
          <div className="row mt-l">{this.getAddOptionField()}</div>
        )}
        {this.state.formData.options.length > 0 && this.getOptionList()}
        <div className={`row align-items-center`}>
          <DKCheckMark
            className="mt-l mb-m"
            isSelected={this.state.formData.required}
            onClick={(value) =>
              this.onFormValueChange("required", !this.state.formData.required)
            }
          />
          <DKLabel text="Is mandatory?" className="ml-s mt-s" />
        </div>
      </>
    );
  };

  getTitle = (type) => {
    return type === INPUT_TYPE_TEXT_AREA ? "Text area" : getCapitalized(type);
  };

  getFieldTypeSelection = () => {
    const INPUT_TYPE_ARR = [
      INPUT_TYPE.TEXT,
      INPUT_TYPE.NUMBER,
      INPUT_TYPE.DATE,
      INPUT_TYPE.SELECT,
      INPUT_TYPE_TEXT_AREA
    ];
    const UPDATED_ARR = [...INPUT_TYPE_ARR];
    if (this.props?.tableName !== TABLES.TICKET) {
      UPDATED_ARR.splice(2, 0, INPUT_TYPE.EMAIL);
      UPDATED_ARR.push(INPUT_TYPE.MULTI_SELECT);
    }
    return (
      <div className="parent-width border">
        <DKLabel text={"Select field type"} />
        <div className={`column-type-wrapper`}>
          {UPDATED_ARR.map((item) => {
            return (
              <div
                className={`p-s bg-white mr-r mt-r border-radius-s border-m d-flex align-items-center ${
                  this.isEditMode ? "cursor-not-allowed" : "cursor-hand"
                }
                            ${
                              this.state.formData.type?.toLowerCase() === item
                                ? "border-blue text-blue fw-m"
                                : ""
                            }`}
                onClick={() => {
                  if (this.isEditMode) return;
                  this.onFormValueChange("type", item);
                  setTimeout(() => this.onFormValueChange("options", []), 0);
                }}
              >
                <DKIcon
                  src={getDataTypeIcon(item)}
                  className="ic-xs cursor-hand"
                />
                <span className="ml-s">{this.getTitle(item)}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  getAddOptionField = () => {
    return (
      <div className="row align-items-end" style={{ width: "80%" }}>
        <ControlledInput
          required
          name="Options"
          invalid={this.state.canValidate}
          type={INPUT_TYPE.TEXT}
          value={this.state.optionFieldText}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(e) => this.onOptionChange(e.target.value)}
          onKeyPress={(e) => this.onOptionKeyPress(e)}
        />
        <DKButton
          title="Add"
          style={{ marginTop: this.state.canValidate ? 10 : 30 }}
          className="border-m ml-r bg-button text-white align-self-center"
          onClick={this.onOptionAdd}
        />
      </div>
    );
  };
  getOptionList = () => {
    return (
      <div className="row pt-s flex-wrap">
        {this.state.formData.options.map((option, index) => {
          return (
            <div
              className={`p-s border-radius-s mr-s mt-s d-flex align-items-center ${option.color}`}
              id={`${option.id}`}
            >
              <span>{option.name}</span>
              <DKIcon
                src={DKIcons.ic_close}
                className="ic-s ml-s cursor-hand"
                onClick={() => this.onOptionRemove(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };
}
export const showAddColumnPopup = (
  config: { tableName: string; data?: any },
  onSave?: (response?: any) => void,
  onClose?: () => void
) => {
  const id = `add-new-col-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <AddColumnPopup
      popupId={id}
      tableName={config.tableName}
      customFieldData={config?.data}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};
export default AddColumnPopup;
