import { useEffect, useRef, useState } from "react";
import {
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKButton,
  DKListPicker2,
  DKIcon,
  showAlert
} from "deskera-ui-library";
import { difference } from "lodash";
import RouteManager from "../../managers/RouteManager";
import SideBarService from "../../services/sidebar";
import SegmentListContainer from "../list/SegmentListContainer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchBooksProducts,
  productSelector
} from "../../redux/slices/booksSlice";
import { COLUMN_CODE, TABLES, TableManger } from "../../managers/TableManger";
import Utility, {
  getScrollEnableSetting,
  redirectToBooksPlus
} from "../../utility/Utility";
import { PRODUCT_TRANSACTION_TYPE, PRODUCT_TYPE } from "../../constants/Enum";
import {
  BOOKS_PRODUCT_GROUP,
  DOC_TYPE,
  PRODUCT_TRANSACTION_TYPE_SALES,
  QTY_ROUND_OFF_PRECISION
} from "../../constants/Constant";
import AddGroupPopup from "./AddGroupPopup";
import {
  deleteProductGroup,
  getProductGroups,
  updateProductGroup
} from "../../services/productGroup";
import { Attachments } from "../common/Attachments";
import debounce from "../../utility/Debounce";
import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import { setUserPreferences } from "../../redux/slices/userPrefSlice";
import { IColumn } from "../../model/Table";
import { isMobileAppWebView } from "../../utility/GetViewportSize";
import AppManager from "../../managers/AppManager";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";

export interface IProductsProps {}
export function Products(props: IProductsProps) {
  const products = useAppSelector(productSelector);
  const listContainerRef = useRef(null);
  const [groupList, setGroupList] = useState([{ name: "All", id: -1 }]);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(-1);
  const [showAddGroupPopup, setShowAddGroupPopup] = useState(false);
  const [showGroupList, setShowGroupList] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortConfig, setSortConfig] = useState({ sort: null, sortDir: null });
  const [page, setPage] = useState(0);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [selectedAttachmentList, setAttachmentList] = useState({} as any);
  const [selectedProductId, setProductId] = useState();
  const [query, setQuery] = useState("active=true,isVariant=false");
  const [filterDataForGrid, setFilterDataForGrid] = useState([]);
  const freezedColumns = useAppSelector(
    (state) => state.userPref.freezedColumns
  );
  const productFreezedColumns =
    (freezedColumns || {})[TABLES.BOOKS_PRODUCT.toUpperCase()] || [];
  const priceBookPermission = useAppSelector((state) =>
    selectPermissionsByModule(state, BOOKS_PRODUCT_GROUP)
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    RouteManager.setPresenter({ props });
    getProductsList();
    getGroups();
  }, []);

  useEffect(() => {
    if (Utility.isEmptyObject(products)) {
      getProductsList();
    }
  }, []);

  useEffect(() => {
    getProductsList();
  }, [currentGroup, sortConfig, page, searchText, query]);

  const getGroups = () => {
    const query = `appName=ERP,module=PRODUCT`;
    getProductGroups({
      limit: 100,
      page: 0,
      search: "",
      query
    }).then((response: any) => {
      setGroupList([{ name: "All", id: -1 }, ...response?.content]);
    });
  };

  const getProductsList = () => {
    let productGroupId = null;
    if (currentGroup !== -1) {
      productGroupId = currentGroup;
    }
    const params = {
      page: page ?? 0,
      limit: 20,
      sort: sortConfig.sort,
      sortDir: sortConfig.sortDir,
      query,
      productGroupId: productGroupId,
      fetchAttachmentDetails: true,
      search: searchText
    };
    if (!query?.includes(COLUMN_CODE.PRODUCTS.TransactionType)) {
      params[COLUMN_CODE.PRODUCTS.TransactionType] =
        PRODUCT_TRANSACTION_TYPE_SALES;
    }
    dispatch(fetchBooksProducts(params));
  };

  const onGroupSelect = (segment) => {
    setCurrentGroup(segment?.id);
    setSelectedRows([]);
    setPage(0);
  };

  const onNewClick = () => {
    setGroupToEdit(null);
    setShowAddGroupPopup(true);
  };
  const onEditClick = (id) => {
    let dataToEdit = groupList.find((gp) => gp.id === id);
    setGroupToEdit(dataToEdit);
    setShowAddGroupPopup(true);
  };
  const deleteGroupConfirmation = (id) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => deleteGroup(id)
      }
    ];
    showAlert(
      "Delete Group?",
      "Deleting this record will product group it permanently you will not be able to restore it.",
      buttons
    );
  };
  const deleteGroup = (id) => {
    deleteProductGroup(id).then((response) => {
      getGroups();
      setCurrentGroup(-1);
    });
  };
  const onSort = ({ order, columnData }) => {
    const sort = columnData?.key;
    const sortDir = order;
    setSortConfig({ sort, sortDir });
  };
  const onColumnFreezeToggle = (data: {
    column: IColumn;
    freezed: boolean;
  }) => {
    if (!data?.column?.id) return;
    const moduleName = TABLES.BOOKS_PRODUCT.toUpperCase();

    let freezedColumnsByModule = productFreezedColumns || [];

    if (data.freezed) {
      freezedColumnsByModule = freezedColumnsByModule.concat(data.column.id);
    } else {
      freezedColumnsByModule = freezedColumnsByModule.filter(
        (columnId) => columnId !== data.column.id
      );
    }

    dispatch(
      setUserPreferences({
        freezedColumns: {
          ...freezedColumns,
          [moduleName]: freezedColumnsByModule
        }
      })
    );
  };
  const onPageChange = (page) => {
    setPage(page - 1);
  };

  const getSegmentControl = () => {
    const listContainer = listContainerRef?.current;
    const canCreate = !!priceBookPermission[USER_ACTION_TYPES.REC_CREATE];
    const canUpdate = !!priceBookPermission[USER_ACTION_TYPES.REC_UPDATE];
    const canDelete = !!priceBookPermission[USER_ACTION_TYPES.REC_DELETE];
    return (
      <SegmentListContainer
        data={groupList}
        selectedId={currentGroup}
        onChange={onGroupSelect}
        onClickNew={onNewClick}
        onClickEdit={onEditClick}
        maxWidth={listContainer?.innerWidth}
        allowToAddSegment={canCreate}
        allowToUpdateSegment={canUpdate}
        allowToDeleteSegment={canDelete}
        onSave={() => {}}
        allowDeleteFromModule={true}
        onClickDelete={deleteGroupConfirmation}
      />
    );
  };

  const onAttachmentCLicked = (data) => {
    setProductId(data.rowData.id);
    setAttachmentList(data.rowData.attachmentsWithLink);
    setShowAttachmentPopup(true);
  };

  const getAttachmentPreviewInPopup = () => {
    return (
      showAttachmentPopup &&
      selectedAttachmentList?.length > 0 && (
        <Attachments
          attachmentList={selectedAttachmentList}
          permissions={priceBookPermission}
          entityId={selectedProductId}
          documentType={DOC_TYPE.PRODUCT_DOCUMENT}
          isReadOnly={true}
          onClose={(docId: any, updatedAttachments: any[]) => {
            setShowAttachmentPopup(false);
          }}
        />
      )
    );
  };

  const getUpdatedColumns = () => {
    const columns: any = [...TableManger.getTableColumns(TABLES.BOOKS_PRODUCT)];
    columns.forEach(
      (col: any) => ((col.key = col.columnCode), (col.editable = false))
    );
    let list = groupList?.filter((group) => group.id !== -1);
    list = list.map((group) => ({
      ...group,
      color: "data-grid-badge-color-1"
    }));
    columns.forEach((col: any) => {
      col.freezed = productFreezedColumns.includes(col.id);
      col.key = col.columnCode;
      if (!col.systemField || col.columnCode === "barcode") {
        col.allowFilter = false;
      }
      if (col.key === "attachments") {
        col.type = "file";
        col.renderer = (obj: any) => {
          if (obj?.rowData?.attachmentsWithLink?.length) {
            return (
              <div className="row">
                <div onClick={() => onAttachmentCLicked(obj)}>
                  <DKIcon
                    src={DKIcons.ic_attachment}
                    className="ic-s cursor-pointer opacity-60 hover:opacity-50"
                  />
                </div>
                <DKButton
                  title="View"
                  className="text-blue text-underline p-v-0"
                  onClick={() => onAttachmentCLicked(obj)}
                />
              </div>
            );
          } else {
            return <></>;
          }
        };
      }
      if (col.key === COLUMN_CODE.PRODUCTS.TransactionType) {
        col.options = col.options?.filter(
          (option) => option.id !== PRODUCT_TRANSACTION_TYPE.PURCHASE
        );
      }
      if (
        ["outgoing", "reorder", "productGroupIds"]?.includes(col.columnCode)
      ) {
        col.allowColumnSort = false;
      }
      if (col.columnCode === "productGroupIds") {
        col.options = list;
        col.editable = true;
      }
    });
    return columns;
  };

  const getUpdatedRows = () => {
    let updatedRows = [...(products?.content || [])];
    const rowData = ProductRowsConfig(updatedRows);
    rowData.forEach((row: any) => {
      if (
        row.type.toLowerCase() === "non_tracked" &&
        row.outgoingQty == `0.00`
      ) {
        row.outgoingQty = "-";
      }
      // row.rowContextMenu = getRowContextMenu(row);
    });
    rowData?.map((row) => {
      row.allowRowEdit =
        !!priceBookPermission[USER_ACTION_TYPES.ASSIGN_PRODUCT];
      row.selected = selectedRows?.includes(row.documentSequenceCode);
    });

    return rowData;
  };

  const getRowContextMenu = (row) => {
    let contextMenu = [];
    contextMenu.push(
      {
        title: "Edit",
        icon: DKIcons.ic_edit,
        onClick: ({ rowIndex, rowData }) => {},
        className: "p-0"
      },
      {
        title: "Assign Product Group",
        icon: DKIcons.ic_product,
        onClick: ({ rowIndex, rowData }) => {},
        className: "p-0"
      },
      {
        title: "Delete",
        icon: DKIcons.ic_delete,
        onClick: ({ rowIndex, rowData }) => {},
        className: "p-0"
      }
    );
    return contextMenu;
  };

  const getAvailableStock = (product: any) => {
    let quantity = product["inventory"]
      ? product["inventory"]["availableQuantity"]
      : 0;
    return isNaN(quantity)
      ? 0
      : Utility.roundingOff(quantity, QTY_ROUND_OFF_PRECISION);
  };

  function outgoingFont(OutgoingAmount: any) {
    const html = `<span class= ' text-red' > ${OutgoingAmount}</span>`;
    return html;
  }

  function ProductRowsConfig(productList: any[]): any {
    let rowData: any[] = [];
    const getCategory = (product: any) => {
      const avdTracking = product["advancedTracking"];
      const productType = product["type"];

      if (avdTracking && productType && avdTracking !== "NONE") {
        const advancedTrackedName: string = avdTracking + " " + productType;
        return Utility.convertInTitleCase(advancedTrackedName);
      } else if (avdTracking && productType && avdTracking === "NONE") {
        return Utility.convertInTitleCase(productType.replaceAll("_", " "));
      }
      return "-";
    };

    const getStatus = (product: any) => {
      return product["active"] ? "ACTIVE" : "INACTIVE";
    };

    productList.forEach((product, index) => {
      const hideIncomingOutgoingQty =
        product.type === PRODUCT_TYPE.BILL_OF_MATERIALS ||
        product.type === PRODUCT_TYPE.NON_TRACKED;
      let row: any = {
        ...product,
        [COLUMN_CODE.PRODUCTS.Number]: product.documentSequenceCode,
        [COLUMN_CODE.PRODUCTS.ProductName]: product.name,
        [COLUMN_CODE.PRODUCTS.Description]: product.description,
        [COLUMN_CODE.PRODUCTS.AvailableStock]: getAvailableStock(product),
        [COLUMN_CODE.PRODUCTS.Status]: [getStatus(product)],
        [COLUMN_CODE.PRODUCTS.Category]: getCategory(product),
        [COLUMN_CODE.PRODUCTS.Id]: product.id ? product.id : index,
        [COLUMN_CODE.PRODUCTS.Reorder]: product.reorderLevel,
        [COLUMN_CODE.PRODUCTS.TransactionType]: product.productTransactionType,
        [COLUMN_CODE.PRODUCTS.Barcode]: product.barcode,
        [COLUMN_CODE.PRODUCTS.Outgoing]: hideIncomingOutgoingQty
          ? ""
          : `${product.incomingQty}/${outgoingFont(product.outgoingQty)}`,
        attachmentsWithLink: product.attachmentsWithLink || []
        // rowContextMenu: getContextMenuForRow(product)
      };
      if (
        product.reorderLevel !== null &&
        Number(getAvailableStock(product)) <= Number(product.reorderLevel)
      ) {
        row = {
          ...product,
          [COLUMN_CODE.PRODUCTS.Number]: product.documentSequenceCode,
          [COLUMN_CODE.PRODUCTS.ProductName]: product.name,
          [COLUMN_CODE.PRODUCTS.AvailableStock]:
            Number(getAvailableStock(product)) === 0
              ? outgoingFont(getAvailableStock(product))
              : getAvailableStock(product),
          [COLUMN_CODE.PRODUCTS.Status]: [getStatus(product)],
          [COLUMN_CODE.PRODUCTS.Category]: getCategory(product),
          [COLUMN_CODE.PRODUCTS.Id]: product.id ? product.id : index,
          [COLUMN_CODE.PRODUCTS.Reorder]: product.reorderLevel,
          [COLUMN_CODE.PRODUCTS.Outgoing]:
            `${product.incomingQty}/${outgoingFont(product.outgoingQty)}`,
          //   rowContextMenu: getContextMenuForRow(product),

          invalidFields: [
            COLUMN_CODE.PRODUCTS.Number,
            COLUMN_CODE.PRODUCTS.ProductName,
            COLUMN_CODE.PRODUCTS.AvailableStock,
            COLUMN_CODE.PRODUCTS.Status,
            COLUMN_CODE.PRODUCTS.Category,
            COLUMN_CODE.PRODUCTS.Id,
            COLUMN_CODE.PRODUCTS.Reorder,
            COLUMN_CODE.PRODUCTS.Outgoing
          ]
        };
      }

      //   if (product.customField && product.customField.length > 0) {
      //     product.customField.forEach((cf: any) => {
      //       const columnCode: string = `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
      //       const colType = getUpdatedColumns()?.find(
      //         (config) => config.columnCode === columnCode
      //       )?.type;
      //       const cfInAvaialbleCFs = availableCustomFields?.content?.find(
      //         (field: any) => field.id === cf.id
      //       );
      //       const isUserTypeCF = cfInAvaialbleCFs
      //         ? cfInAvaialbleCFs.fieldType === CUSTOM_FIELD_TYPE.USER
      //         : false;
      //       let cfValue = '';
      //       if (isUserTypeCF) {
      //         const tempCF = cfInAvaialbleCFs?.attributes?.find(
      //           (attr: any) => attr.code === cf.value
      //         );
      //         cfValue = tempCF ? tempCF.value : '';
      //       } else {
      //         cfValue = cf ? cf.value : '';
      //       }
      //       row = {
      //         ...row,
      //         [columnCode]: colType === INPUT_TYPE.SELECT ? [cfValue] : cfValue
      //       };
      //     });
      //   }
      rowData.push(row);
    });
    return rowData || [];
  }

  const getDocumentGridHeaderButtons = () => {
    const canCreate = !!priceBookPermission[USER_ACTION_TYPES.REC_CREATE];
    let buttons = [];
    canCreate &&
      buttons.push({
        title: `+ Add Product`,
        className: "bg-button text-white ml-r",
        onClick: () => redirectToBooksPlus()
      });
    return buttons;
  };

  const onGroupSave = () => {
    getGroups();
    getProductsList();
    setShowAddGroupPopup(false);
  };
  const onRowSelect = ({ rowData }) => {
    if (!rowData?.documentSequenceCode) return;

    let newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(rowData.documentSequenceCode)) {
      newSelectedRows = newSelectedRows.filter(
        (productId) => productId !== rowData.documentSequenceCode
      );
    } else {
      newSelectedRows.push(rowData.documentSequenceCode);
    }
    setSelectedRows(newSelectedRows);
    if (selectedRows.length === 0) {
      setSelectedIndexes([]);
    }
  };
  const onAllRowSelect = ({ selected }) => {
    let newSelectedRows = [];

    if (selected) {
      newSelectedRows = products?.content?.map(
        (product) => product.documentSequenceCode
      );
    }
    setSelectedRows(newSelectedRows);
  };
  const onSelect = (index, selectedObj) => {
    let indexes = [...selectedIndexes];
    if (indexes.includes(index)) {
      indexes = indexes.filter((index1) => index1 !== index);
    } else {
      indexes.push(index);
    }
    setSelectedIndexes(indexes);
  };
  const getGroupListPicker = () => {
    let list = groupList
      ?.map((group) => ({ id: group.id, name: group.name }))
      ?.filter((list) => list.id !== -1 && list.id !== currentGroup);
    return (
      <DKListPicker2
        onChange={() => {}}
        onSelect={(index, selectedObj) => {
          onSelect(index, selectedObj);
        }}
        data={list}
        searchableKey={"name"}
        renderer={(index, obj) => {
          return (
            <div
              style={{
                overflowWrap: "break-word",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {obj?.name}
            </div>
          );
        }}
        style={{
          maxWidth: 250
        }}
        allowSearch={true}
        title={""}
        className="position-absolute z-index-3 shadow-m border-s"
        multiSelect={true}
        selectedIndexes={selectedIndexes}
        onClose={() => {
          setShowGroupList(false);
          // setSelectedIndexes([])
        }}
        onClear={() => setSelectedIndexes([])}
        // searchApiConfig={{
        // 	method: 'GET',
        // 	getUrl: (search: string) => `${ApiConstants.URL.BASE}${ApiConstants.URL.PRODUCT_GROUP.GET_ALL}?search=${search}`,
        // 	dataParser: (response) => {
        // 		return response.content;
        // 	},
        // 	debounceTime: 300,
        // }}
      />
    );
  };
  const onApply = () => {
    let payload = [];
    const groupListWithoutAll = Utility.deepCloneObject(groupList);
    groupListWithoutAll.shift();

    selectedIndexes.forEach((index) => {
      let uniqueGroupItems = [...selectedRows];
      if (
        !Utility.isEmptyObject(groupListWithoutAll[index]["productGroupItems"])
      ) {
        uniqueGroupItems.push(
          ...groupListWithoutAll[index]["productGroupItems"]?.map(
            (item) => item["productId"]
          )
        );
      }
      uniqueGroupItems = Array.from(new Set(uniqueGroupItems));

      payload.push({
        ...groupListWithoutAll[index],
        productGroupItems: uniqueGroupItems.map((item) => ({ productId: item }))
      });
    });
    Promise.all(
      payload.map((dataToSend) =>
        updateProductGroup(dataToSend, dataToSend?.id)
      )
    ).then((response) => {
      getGroups();
      setSelectedRows([]);
      setSelectedIndexes([]);
      getProductsList();
    });
  };
  const onRowUpdate = ({ columnData = null, columnKey, rowData, rowIndex }) => {
    const existingRows = getUpdatedRows();
    if (columnKey === "productGroupIds") {
      const existingRow = existingRows.find((row) => row.id === rowData.id);
      let groupAdded = true;
      let groupIdsToUpdate = difference(
        rowData.productGroupIds,
        existingRow?.productGroupIds ?? []
      );
      // Checking if product has been added
      if (Utility.isEmptyObject(groupIdsToUpdate)) {
        // Checking if product has been removed
        groupIdsToUpdate = difference(
          existingRow?.productGroupIds ?? [],
          rowData.productGroupIds
        );
        groupAdded = false;
      }
      const groupIdProductMap = new Map<string, any>();
      if (!Utility.isEmptyObject(groupIdsToUpdate)) {
        groupIdsToUpdate.forEach((groupId) => {
          const group: any = groupList.find((item) => item.id === groupId);
          if (!Utility.isEmptyObject(group)) {
            let productGroupItems = Utility.deepCloneObject(
              group?.productGroupItems ?? []
            );
            if (!groupAdded) {
              productGroupItems = productGroupItems.filter(
                (product) => product.productId !== rowData.documentSequenceCode
              );
            } else {
              productGroupItems.push({
                productId: rowData.documentSequenceCode
              });
            }
            groupIdProductMap.set(group.id, productGroupItems);
          }
        });
      }
      const apiCallArray = [];
      groupIdProductMap.forEach((productGroupItemIds, groupId) =>
        apiCallArray.push(
          updateProductGroup(
            { productGroupItems: productGroupItemIds },
            groupId
          )
        )
      );
      Promise.all(apiCallArray).then((response) => {
        getGroups();
        setSelectedRows([]);
        setSelectedIndexes([]);
        getProductsList();
      });
    }
  };

  const onSearch = debounce((searchQuery) => {
    setSearchText(searchQuery);
  }, 500);

  const onFilterApply = (filterData) => {
    const { query } = filterData;
    setFilterDataForGrid(query);
    const columns = getUpdatedColumns() || [];
    let queryArray: any = [{ isVariant: false }];
    let queryString = "";
    query.forEach((item) => {
      let columnToFilter = columns?.find((column) => column.id === item.key);
      let columnCode = columnToFilter?.columnCode;
      let value = item?.value?.[0] || item.value;
      if (columnCode === "status") {
        columnCode = "active";
        value = value === "ACTIVE" ? true : false;
      }
      if (columnCode === "type") {
        value = value === "NON_TRACKED" ? "NONTRACKED" : value;
      }
      queryArray.push({ [columnCode]: value });
      if (!Utility.isEmptyObject(queryArray)) {
        queryString = Utility.getQueryString(queryArray);
      }
    });
    setQuery(queryString);
  };
  const getNoDataView = (canView) => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute parent-height justify-content-center"
        style={{ pointerEvents: "none" }}
      >
        <DKIcon src={ic_no_data} className="ic-l" style={{ opacity: 0.2 }} />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text={
            canView
              ? "Looks like you don't have permission to view product groups, please contact your organization owner."
              : "Once data is available, it will appear here"
          }
          className="text-gray mt-s "
        />
      </div>
    );
  };

  const canCreate = !!priceBookPermission[USER_ACTION_TYPES.REC_CREATE];
  const canView = !!priceBookPermission[USER_ACTION_TYPES.REC_VIEW];
  return (
    <div
      className="column mt-s parent-width mb-s position-relative flex-1"
      ref={listContainerRef}
    >
      <div className="row ">
        <DKLabel text="All Groups" className="fw-m fs-m" />
        {canCreate && (
          <DKButton
            title="+ New Group"
            className=" bg-white border-m ml-r"
            onClick={() => {
              setGroupToEdit(null);
              setShowAddGroupPopup(true);
            }}
          />
        )}
      </div>
      {canView && getSegmentControl()}
      {showAddGroupPopup && (
        <AddGroupPopup
          permissions={priceBookPermission}
          dataToEdit={groupToEdit}
          onCancel={() => setShowAddGroupPopup(false)}
          onSave={() => onGroupSave()}
        />
      )}
      {selectedRows?.length !== 0 && (
        <div
          className="row justify-content-between z-index-2"
          style={{ width: SideBarService.getContainerWidth() }}
        >
          <DKLabel className="fs-m fw-b" text="Products" />
          <div className="column parent-width" style={{ flexDirection: "row" }}>
            <div className="position-relative ml-xl">
              <div
                className="bg-white border-m border-radius-m row width-auto cursor-hand p-h-m"
                style={{ height: 36 }}
                onClick={() => setShowGroupList(true)}
              >
                <DKLabel
                  text={`Product Group`}
                  className="text-ellipsis"
                  style={{ minWidth: 100, maxWidth: 200 }}
                />
                <DKIcon src={DKIcons.ic_arrow_down2} className={"ic-s ml-s"} />
              </div>
              <div
                className="position-absolute border-box shadow-m z-index-5 parent-width"
                style={{ top: 36, left: 0 }}
              >
                {showGroupList && getGroupListPicker()}
              </div>
            </div>
            {selectedIndexes?.length > 0 && (
              <DKButton
                title="Apply"
                className="border-green ml-r bg-green text-white"
                onClick={() => onApply()}
              />
            )}
          </div>
          {canCreate && (
            <div className="column">
              <DKButton
                title={"+ Add Product"}
                className="bg-button text-white"
                onClick={redirectToBooksPlus}
              />
            </div>
          )}
        </div>
      )}
      {canView && (
        <DKDataGrid
          title={"Products"}
          width={SideBarService.getContainerWidth()}
          gridStickyHeaderConfig={
            isMobileAppWebView()
              ? null
              : {
                  getRootScrollContainer: () =>
                    AppManager.getScrollContainerDiv()
                }
          }
          allowRowEdit={true}
          allowBulkOperation={
            !!priceBookPermission[USER_ACTION_TYPES.ASSIGN_PRODUCT]
          }
          allowColumnSort={true}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowColumnFreeze={true}
          allowSearch={true}
          allowFilter={true}
          buttons={getDocumentGridHeaderButtons()}
          columns={getUpdatedColumns() ?? []}
          rows={getUpdatedRows()}
          isAllRowSelected={
            !Utility.isEmptyObject(selectedRows) &&
            selectedRows?.length === products?.content?.length
          }
          onRowSelect={(data) => onRowSelect(data)}
          onRowUpdate={onRowUpdate}
          onAllRowSelect={(data) => onAllRowSelect(data)}
          onSearch={(data) => onSearch(data)}
          onSort={onSort}
          onColumnFreezeToggle={onColumnFreezeToggle}
          onFilter={onFilterApply}
          filterData={filterDataForGrid}
          currentPage={products?.pageable?.pageNumber + 1}
          onPageChange={onPageChange}
          showHeader={selectedRows?.length === 0}
          totalPageCount={products?.totalPages}
          customScrollConfig={getScrollEnableSetting()}
        />
      )}
      {(Utility.isEmptyObject(getUpdatedRows() || []) || !canView) &&
        getNoDataView(!canView)}
      {getAttachmentPreviewInPopup()}
    </div>
  );
}
export default Products;
