import {
  DKLabel,
  DKButton,
  DKIcons,
  DKIcon,
  showToast,
  showAlert
} from "deskera-ui-library";

import { useEffect, useState } from "react";
import { DOC_TYPE } from "../../constants/Constant";
import Utility from "../../utility/Utility";
import Popup from "./Popup";
import AttachmentService from "../../services/Attachment";
import { triggerDownload } from "../import_export/utility/ExportData";
import { USER_ACTION_TYPES } from "../../constants/Permission";

interface AttachmentProps {
  documentDetails?: any;
  attachmentList?: any;
  entityId: any;
  permissions: any;
  documentType: DOC_TYPE;
  onClose: (docId: any, updatedAttachments: any[]) => void;
  isReadOnly?: boolean;
}

export function Attachments(props: AttachmentProps) {
  // const [doc, setDoc] = useState(props.documentDetails);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [updateProgress, setUpdateProgress] = useState<any>(null);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(
    props.isReadOnly || false
  );

  ////////////////////////////////////////////////////
  ////////// ATTACHMENT FUNCTIONALITIES //////////////
  ////////////////////////////////////////////////////
  const fetchAttachments = async () => {
    const entityId = props.entityId;
    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: props.documentType,
      EntityId: entityId
    };
    try {
      const allAttachments = await AttachmentService.getAllAttachments();
      setAttachments(allAttachments);
    } catch (err) {
      console.error("Error loading attachments: ", err);
    }
  };

  const uploadAttachmentToAWS = (file: File) => {
    const entityId = props.entityId || "";
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: props.documentType,
      EntityId: entityId
    };
    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        "Attachment size limit exceeded",
        "It seems the file size is more than 5 MB, Please compress the file and try again."
      );
      return;
    }
    setIsUploading(true);
    AttachmentService.uploadAttachment(file, setUpdateProgress)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        setAttachments(attachmentForListing);
        setIsUploading(false);
      })
      .catch((err) => {
        showToast(
          "Something went wrong while uploading the attachment, please try again."
        );
        setIsUploading(false);
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.addEventListener("change", (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast("Something went wrong, while downloading the attachment.");
      });
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          "Something went wrong while removing the attachment, please try again."
        );
        setIsRemovingAttachment(false);
      });
  };

  // useEffect(() => {
  //   fetchAttachments();
  // }, []);

  useEffect(() => {
    if (!Utility.isEmptyObject(updateProgress)) {
      if (updateProgress.percentComplete === 100) {
        setTimeout(() => {
          setUpdateProgress(null);
        }, 1000);
      }
    }
  }, [updateProgress]);

  const getBody = () => {
    return (
      <div className="column parent-width parent-height p-h-r p-v-s pb-xl overflow-y-auto">
        {props.attachmentList.length === 0 && (
          <DKLabel
            text="No attachment here. Select file to upload."
            clasName="parent-width"
          />
        )}
        {updateProgress && (
          <div
            className={`column parent-width border-m z-index-2 p-h-s p-v-s bg-gray0 mt-r justify-content-between position-absolute bottom-0 left-0`}
            style={{ borderLeft: 0, borderRight: 0, borderBottom: 0 }}
          >
            <DKLabel
              text={`Uploading: <span class="fw-m">${updateProgress?.fileName}</span>`}
              className="row"
            />
            <div
              className="row mt-r bg-white border-m border-radius-m"
              style={{ height: 12 }}
            >
              <div
                className="column bg-blue parent-height border-radius-m"
                style={{ width: `${updateProgress?.percentComplete}%` }}
              ></div>
            </div>
            <DKLabel
              text={`${updateProgress?.percentComplete}%`}
              className="parent-width text-align-center mt-xs"
            />
          </div>
        )}
        {props.attachmentList.map((attachment: any, index: number) => (
          <div className="row p-v-s">
            <div
              className="row align-items-end text-right"
              style={{ width: "6%" }}
            >
              <DKLabel text={`${index + 1}.`} />
            </div>
            <div
              className={`row border-m border-radius-s p-h-s p-v-s bg-gray0 justify-content-between ${
                isRemovingAttachment ? "pointer-events-none" : ""
              }`}
              key={attachment.attachmentId}
              style={{ width: "96%" }}
            >
              <div className="row items-center">
                <DKIcon
                  src={DKIcons.ic_document}
                  className="column ic-s  cursor-hand opacity-60"
                  onClick={() => {
                    props.permissions[USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD] &&
                      triggerAttachmentDownload(
                        attachment.attachmentId,
                        attachment.attachmentFileName
                      );
                  }}
                />
                <span
                  onClick={() => {
                    props.permissions[USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD] &&
                      triggerAttachmentDownload(
                        attachment.attachmentId,
                        attachment.attachmentFileName
                      );
                  }}
                >
                  <DKLabel
                    text={attachment.attachmentFileName}
                    className="column ml-s  cursor-hand border-none"
                    onClick={() => {
                      props.permissions[
                        USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD
                      ] &&
                        triggerAttachmentDownload(
                          attachment.attachmentId,
                          attachment.attachmentFileName
                        );
                    }}
                  />
                </span>
              </div>
              {!isReadOnlyMode &&
                props.permissions[USER_ACTION_TYPES.ATTACHMENT_DELETE] && (
                  <div className="row items-center">
                    <DKIcon
                      src={DKIcons.ic_delete}
                      className="ic-s  cursor-hand opacity-60 hover:opacity-80"
                      onClick={() => {
                        removeAttachment(attachment.attachmentId);
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        ))}
        {!isReadOnlyMode &&
          props.permissions[USER_ACTION_TYPES.ATTACHMENT_UPLOAD] && (
            <DKButton
              title={
                <>
                  + Attach Files
                  <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                </>
              }
              className={`${
                isUploading ? "text-gray" : "text-blue"
              } mt-r fw-m p-h-0`}
              style={{ paddingLeft: 0 }}
              disabled={isUploading}
              onClick={triggerAttachmentUpload}
            />
          )}
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-s p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text="Attachments" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={"Close"}
            className="bg-white border-m"
            onClick={() => {
              props.onClose(props.entityId, attachments);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Popup popupWindowStyles={{ padding: 0 }}>
      {getHeader()}
      {getBody()}
    </Popup>
  );
}
