import { Component, createRef } from "react";
import ReactDOM from "react-dom";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./quillStyles.scss";

import Popup from "../common/Popup";
import ApiManager from "../../managers/ApiManager";
import { TABLES, TableManger, COLUMN_CODE } from "../../managers/TableManger";
import {
  DKInput,
  DKButton,
  INPUT_TYPE,
  DKLabel,
  DKIcon,
  DKIcons,
  DKListPicker2,
  showAlert
} from "deskera-ui-library";
import { isEqual } from "lodash";
import { showEmailTemplatePopup } from "./AddEmailTemplatePopup";
import TableDataParser from "../../Helper/TableDataParser";
import { store } from "../../redux/store";
import {
  deleteRecord,
  fetchRecordsByTable
} from "../../redux/slices/recordSlice";
import Table from "../../services/table";
import Utility, { getCapitalized } from "../../utility/Utility";
import { FILTER_OPERATORS, TEMPLATE_MODULE_ID } from "../../constants/Enum";

/** PROPS
 * tableName?: string;
 * editorID: string;
 * additionalPlaceholders ?: {id, name}[];
 * additionalTableNames ?: string[];
 * body?: string;
 * bodyPlaceholder?: string;
 * height?: number;
 * needEmailPlaceholderTool?: boolean;
 * needEmailTemplateTool?: boolean;
 * getBodyToSaveCallback?: (callback) => void;
 */

class EmailEditorQuill extends Component {
  constructor(props) {
    super(props);

    this.editorContainerRef = createRef();
    this.quillEditor = null;
    this.tableColumns = this.getTableColumnsForEditor(
      this.props.tableName,
      this.props.additionalPlaceholders,
      this.props.additionalTableNames
    );
    this.state = {
      needHyperLinkPopup: false,
      linkText: "",
      linkURL: "",
      linkTextError: false,
      linkURLError: false,
      needTemplateSelector: false
    };

    this.templateTableColumnCodeIdMap = {};
  }

  setColumnsByTable(tableName, idToNameMap = {}, isAdditional = false) {
    if (!tableName) {
      tableName = TABLES.CONTACT;
    }

    const tableColumnsData = TableManger.getTableFilteredColumns(
      tableName,
      (col) =>
        ((col.allowPlaceholder ?? (col.uiVisible && col.editable)) &&
          ![
            COLUMN_CODE.CONTACT.ACCOUNT,
            COLUMN_CODE.CONTACT.DETAILED_ADDRESS
          ].includes(col.columnCode)) ||
        (tableName === TABLES.DEAL &&
          [COLUMN_CODE.DEAL.NOTE, COLUMN_CODE.DEAL.STAGE_ID].includes(
            col.columnCode
          )) ||
        (tableName === TABLES.ACTIVITY &&
          [
            COLUMN_CODE.ACTIVITY.START_DATE,
            COLUMN_CODE.ACTIVITY.END_DATE
          ].includes(col.columnCode))
    );

    tableColumnsData.forEach((column) => {
      const key = isAdditional ? `${tableName}_${column.id}` : column.id;
      idToNameMap[key] =
        `${getCapitalized(tableName)}.${column?.systemField ? getCapitalized(column.name.toLowerCase()) : column.name}`;
      if (column.columnCode === "name" && !isAdditional) {
        this.nameColumnId = key;
      }
    });
  }

  getTableColumnsForEditor(
    tableName,
    additionalPlaceholders = [],
    additionalTableNames = []
  ) {
    const placeholderKeyNameMap = {};

    if (additionalPlaceholders?.length) {
      additionalPlaceholders.forEach((placeholderData) => {
        placeholderKeyNameMap[placeholderData.id] =
          `${getCapitalized(placeholderData.name.toLowerCase())}`;
      });
    }

    this.setColumnsByTable(tableName, placeholderKeyNameMap);

    additionalTableNames?.forEach((table) =>
      this.setColumnsByTable(table, placeholderKeyNameMap, true)
    );

    return placeholderKeyNameMap;
  }

  componentDidMount() {
    this.setupQuillEditor(true);

    if (this.props.needEmailTemplateTool) {
      TableManger.getTableColumns(TABLES.TEMPLATE)?.forEach((column) => {
        this.templateTableColumnCodeIdMap[column.columnCode] = column.id;
      });
      store.dispatch(
        fetchRecordsByTable({
          tableName: TABLES.TEMPLATE
        })
      );
    }

    this.props.getBodyToSaveCallback?.(this.getEditorParsedBody);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.body && nextProps.body !== this.props.body) {
      this.clearBody();
      this.initializeBody(nextProps, true);
    }

    if (
      !isEqual(nextProps.tableName, this.props.tableName) ||
      !isEqual(
        nextProps.additionalPlaceholders,
        this.props.additionalPlaceholders
      ) ||
      !isEqual(nextProps.additionalTableNames, this.props.additionalTableNames)
    ) {
      this.tableColumns = this.getTableColumnsForEditor(
        nextProps.tableName,
        nextProps.additionalPlaceholders,
        nextProps.additionalTableNames
      );
      this.setupQuillEditor(false, nextProps);
    }
  }

  render() {
    return (
      <div ref={this.editorContainerRef} className="parent-width">
        <div
          className={"parent-width"}
          id={this.props.editorID}
          style={{ height: this.props.height ? this.props.height : 300 }}
          onDrop={this.handleFileDrop}
        ></div>
        {this.getHyperLinkPopup()}
      </div>
    );
  }

  /* ***************  INITIALIZERS START ****************** */
  setupQuillEditor = (initializeBody = true, props = this.props) => {
    try {
      /* Remove existing toolbar inside editor container if any */
      this.editorContainerRef.current?.querySelector(".ql-toolbar")?.remove();

      this.setEditorCustomizations(Quill);
      this.quillEditor = new Quill(`#${this.props.editorID}`, {
        ...this.getQuillConfig(),
        bounds: `#${this.props.editorID}`,
        placeholder: this.props.bodyPlaceholder || "Compose an email..."
      });

      this.initializeBody(props, initializeBody);
      this.initializeAttachment();
      this.initializeTemplateSelector();
      this.setupCustomFormatLabels();
    } catch (e) {
      console.log("Failed to setup quill editor", e);
    }
  };

  getQuillConfig = () => {
    const customTools = [];

    if (this.props.needEmailTemplateTool) {
      customTools.push("email-template");
    }

    if (this.props.needEmailPlaceholderTool) {
      customTools.push({ "email-placeholder": Object.keys(this.tableColumns) });
    }

    return {
      theme: "snow",
      modules: {
        toolbar: {
          container: [
            [
              { size: ["10px", "13px", "16px", "18px", "24px"] },
              { color: [] },
              { background: [] }
            ],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
            ["image", "link", "dk_attachment"],
            customTools
          ],
          handlers: {
            image: () => this.triggerAttachmentDialog(true),
            link: this.showHyperLinkPopup,
            dk_attachment: () => this.triggerAttachmentDialog(false),
            "email-placeholder": this.handlePlaceholderInsertion
          }
        },
        clipboard: {
          matchVisual: false
        }
      }
    };
  };

  setEditorCustomizations = (quill) => {
    /* forcing quill editor to place alignment & size as inline-styles instead of classes */
    const AlignStyle = quill.import("attributors/style/align");
    quill.register({ "formats/align": AlignStyle }, true);

    const SizeStyle = quill.import("attributors/style/size");
    SizeStyle.whitelist = ["10px", "13px", "16px", "18px", "24px"];
    quill.register({ "formats/size": SizeStyle }, true);

    const Block = quill.import("blots/block");
    Block.tagName = "div";
    quill.register(Block, true);

    this.setFieldListFormatter(quill);
  };

  clearBody = () => {
    const bodyLength = this.quillEditor?.getLength?.();
    if (bodyLength) {
      this.quillEditor.deleteText(0, bodyLength);
    }
  };

  initializeBody = (nextProps = this.props, insertBody = true) => {
    let { body, tableName, additionalTableNames } = nextProps;

    if (body && insertBody) {
      if (this.nameColumnId) {
        body = body.replace(
          `%name%`,
          `<span class="custom_email_placeholder" data-id=${this.nameColumnId}>%${this.nameColumnId}%</span>`
        );
      }

      this.quillEditor.clipboard.dangerouslyPasteHTML(0, body);
      this.quillEditor.blur();
    }

    /* updating email-placeholders text if present in body before hand */
    const placeholders =
      this.editorContainerRef.current?.getElementsByClassName(
        "custom_email_placeholder"
      );
    placeholders &&
      Array.from(placeholders).forEach((placeholder) => {
        let dataId = placeholder.getAttribute("data-id");
        dataId = dataId.replace("custom_field_", "custom_field.");
        let columnName = this.tableColumns[dataId];

        /* As part of ZEN:8937
          In case of additional tableName, placeholders are inserted with key `objectType_id`
          to identify placeholder by object type when multiple tables are present.
          Hence identifying column name with object types before removing placeholder
        */
        if (dataId && !columnName) {
          if ([TABLES.DEAL, TABLES.ACTIVITY].includes(tableName)) {
            dataId = dataId.toString().replace(`${tableName}_`, "");
            columnName = this.tableColumns[dataId];
          } else if (!Utility.isEmptyObject(additionalTableNames)) {
            additionalTableNames.some((tableName) => {
              dataId = `${tableName}_${dataId}`;
              columnName = this.tableColumns[dataId];
              return !Utility.isEmptyObject(columnName);
            });
          }

          if (columnName) {
            placeholder.setAttribute("data-id", dataId);
          }
        }

        if (columnName) {
          placeholder.innerHTML = `<span contenteditable="false">${columnName}</span>`;
        } else {
          placeholder.remove();
        }
      });
  };

  getEditorParsedBody = (
    parsePlaceholders = true,
    hidePlaceholderStyles = false
  ) => {
    let container = this.quillEditor?.root,
      body = "";
    try {
      if (!container) {
        container = document.getElementById(`#${this.props.editorID}`);
      }

      if (container.classList.contains("ql-blank")) return "";

      body = container.innerHTML;

      if (parsePlaceholders) {
        const placeholders = container.getElementsByClassName(
          "custom_email_placeholder"
        );
        Array.from(placeholders).forEach((placeholder) => {
          const dataId = placeholder.getAttribute("data-id");
          placeholder.innerHTML = `%${dataId}%`;
          hidePlaceholderStyles && placeholder.classList.add("-no-style");
        });
      }

      body = container.innerHTML;
    } catch (err) {
      console.log("Error while parsing editor body", err);
    }

    return body;
  };

  /************** CUSTOM PLACEHOLDERS **************/
  setFieldListFormatter = (quill) => {
    const Embed = quill.import("blots/embed");

    class EmailPlaceholder extends Embed {
      static create(value) {
        let node = super.create(value);
        let replaceDataIdWith = value.dataId?.replace(
          "custom_field.",
          "custom_field_"
        );
        node.setAttribute("data-id", replaceDataIdWith);
        node.textContent = value.displayName;
        return node;
      }

      static value(node) {
        return {
          dataId: node.getAttribute("data-id"),
          displayName: node.textContent
        };
      }
    }

    EmailPlaceholder.blotName = "email-placeholder";
    EmailPlaceholder.className = "custom_email_placeholder";
    EmailPlaceholder.tagName = "SPAN";

    quill.register({ "formats/email-placeholder": EmailPlaceholder }, true);
  };

  setupCustomFormatLabels = () => {
    const placeholderFormats = this.editorContainerRef.current.querySelectorAll(
      ".ql-email-placeholder .ql-picker-item"
    );
    Array.from(placeholderFormats).forEach((placeholderElement) => {
      const columnID = placeholderElement.getAttribute("data-value");
      placeholderElement.textContent = this.tableColumns[columnID];
    });

    this.quillEditor.root.addEventListener("click", this.editorClickHandler);
  };

  handlePlaceholderInsertion = (columnId) => {
    if (columnId) {
      const range = this.quillEditor.getSelection(true);

      if (range.length) {
        const selectedText = this.quillEditor.getText(
          range.index,
          range.length
        );
        this.quillEditor.deleteText(range.index, selectedText.trimEnd().length);
      }

      this.quillEditor.insertEmbed(range.index, "email-placeholder", {
        dataId: columnId,
        displayName: this.tableColumns[columnId]
      });

      this.quillEditor.insertText(range.index + 1, " ");
      this.quillEditor.setSelection(range.index + 2);
    }
  };

  editorClickHandler = (event) => {
    const target = event.target;
    const emailPlaceholderElement = target?.closest(
      ".custom_email_placeholder"
    );

    const placeholderButton = this.editorContainerRef.current?.querySelector(
      ".ql-email-placeholder .ql-picker-label"
    );

    if (!placeholderButton) {
      return;
    } else if (emailPlaceholderElement) {
      placeholderButton.classList.add("ql-active");
      const dataId = emailPlaceholderElement.getAttribute("data-id");
      placeholderButton.setAttribute("data-value", dataId);

      placeholderButton.setAttribute("data-title", this.tableColumns[dataId]);
    } else {
      placeholderButton.classList.remove("ql-active");
    }
  };

  /************** ATTACHMENT HANDLERS **************/
  initializeAttachment = () => {
    const attachmentButton =
      this.editorContainerRef.current?.querySelector(".ql-dk_attachment");
    if (attachmentButton) {
      attachmentButton.innerHTML = `
      <svg viewBox="0 0 512 512">
        <path class="ql-even ql-stroke" d="M467.076,68.86c-59.902-59.902-156.846-59.896-216.741,0L34.919,284.276c-46.558,46.557-46.558,122.312,0,168.87
          c46.57,46.571,122.326,46.544,168.87,0L419.205,237.73c33.36-33.36,33.36-87.64,0-121c-33.359-33.361-87.64-33.361-121,0
          L114.478,300.457c-6.975,6.975-6.975,18.285,0,25.259c6.975,6.975,18.285,6.975,25.259,0l183.727-183.727
          c19.432-19.432,51.05-19.432,70.481,0c19.431,19.432,19.431,51.05,0,70.481L178.53,427.887c-32.71,32.71-85.646,32.706-118.352,0
          c-15.806-15.806-24.511-36.821-24.511-59.175s8.706-43.369,24.511-59.176L275.594,94.119c45.94-45.94,120.287-45.934,166.222,0
          c45.827,45.828,45.827,120.395,0,166.222l-95.741,95.741c-6.975,6.975-6.975,18.284,0,25.259s18.285,6.975,25.259,0l95.741-95.741
          C526.978,225.7,526.971,128.754,467.076,68.86z"/>
      </svg>`;
    }
  };

  handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files?.length) {
      Array.from(files).forEach((file) => {
        this.uploadAttachmentToAWS(
          file,
          new RegExp("^(image/)").test(file.type)
        );
      });
    }
  };

  triggerAttachmentDialog = (isImage) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.accept = isImage ? "image/*" : "*";
    input.addEventListener("change", (e) => {
      if (e.target.files.length > 0) {
        this.uploadAttachmentToAWS(e.target.files[0], isImage);
      }
    });
    input.click();
  };

  onAttachmentUploaded = (url, fileData, isImage) => {
    if (isImage) {
      const range = this.quillEditor.getSelection(true);
      this.quillEditor.insertEmbed(range.index, "image", url);
    } else {
      this.setState(
        {
          linkText: fileData?.name || `Untitled`,
          linkURL: url
        },
        this.onInsertHyperLink
      );
    }
  };

  uploadAttachmentToAWS = (fileData, isImage) => {
    ApiManager.getUploadFileURL(
      fileData,
      (url) => this.onAttachmentUploaded(url, fileData, isImage),
      (err) => {},
      "CRM_ATTACHMENT_EMAILS"
    );
  };

  /************** HYPER-LINK HANDLERS **************/
  showHyperLinkPopup = (e) => {
    const range = this.quillEditor.getSelection();
    let linkText = "";

    if (range && range.length) {
      linkText = this.quillEditor.getText(range.index, range.length);
    }

    this.setState((prev) => ({
      needHyperLinkPopup: true,
      linkText: linkText
    }));
  };

  closeHyperLinkPopup = () => {
    this.setState({
      needHyperLinkPopup: false,
      linkText: "",
      linkURL: "",
      linkTextError: false,
      linkURLError: false
    });
  };

  getAbsoluteURL(url) {
    const regex = /^https?:\/{2}(\w)?/i;
    const mailLinkRegex = /^mailto:\w/i;
    if (!url || regex.test(url) || mailLinkRegex.test(url)) return url;

    return `//${url}`;
  }

  onInsertHyperLink = () => {
    const { linkText, linkURL } = this.state;
    if (linkText && linkURL) {
      const range = this.quillEditor.getSelection(true);
      if (range && range.length) {
        const selectedText = this.quillEditor.getText(
          range.index,
          range.length
        );
        this.quillEditor.deleteText(range.index, selectedText.trimEnd().length);
      }

      this.quillEditor.insertText(
        range.index,
        linkText.trim(),
        "link",
        this.getAbsoluteURL(linkURL)
      );

      this.quillEditor.setSelection(range.index, 0);

      this.closeHyperLinkPopup();
    } else {
      this.setState({
        linkTextError: linkText ? false : true,
        linkURLError: linkURL ? false : true
      });
    }
  };

  getHyperLinkPopup = () => {
    return this.state.needHyperLinkPopup ? (
      <Popup
        allowClose={true}
        onClose={this.closeHyperLinkPopup}
        title="Insert Link"
      >
        <div className="column">
          <DKInput
            className="mt-xl"
            title="Text"
            type={INPUT_TYPE.TEXT}
            value={this.state.linkText}
            canValidate={this.state.linkTextError}
            // invalid={this.state.linkTextError}
            onChange={(val) => this.setState({ linkText: val })}
          />
          <DKInput
            className="mt-r"
            title="URL"
            type={INPUT_TYPE.TEXT}
            value={this.state.linkURL}
            canValidate={this.state.linkURLError}
            // invalid={this.state.linkURLError}
            onChange={(val) => this.setState({ linkURL: val })}
          />
          <div className="row justify-content-end align-items-center mt-xl">
            <a
              className="justify-self-start"
              href={this.getAbsoluteURL(this.state.linkURL)}
              style={{
                marginRight: "auto"
              }}
              target="_blank"
              rel="noreferrer"
            >
              Preview Link
            </a>
            <DKButton
              title="Cancel"
              className="bg-white border-m mr-s"
              onClick={this.closeHyperLinkPopup}
            />
            <DKButton
              title="Save"
              className="bg-button text-white"
              onClick={this.onInsertHyperLink}
            />
          </div>
        </div>
      </Popup>
    ) : null;
  };

  /************** EMAIL TEMPLATE HANDLERS **************/
  initializeTemplateSelector = () => {
    const templateButton =
      this.editorContainerRef.current?.querySelector(".ql-email-template");

    if (!templateButton) return;

    ReactDOM.render(this.renderTemplateSelector(), templateButton);
  };

  toggleTemplateSelector = (shouldOpen) => {
    this.setState(
      (prevState) => ({
        needTemplateSelector: shouldOpen ?? !prevState.needTemplateSelector
      }),
      () => this.initializeTemplateSelector()
    );
  };

  templateRowRenderer = (index, templateData) => {
    const templateName =
      templateData.cells[
        this.templateTableColumnCodeIdMap[COLUMN_CODE.TEMPLATE.NAME]
      ];

    return <DKLabel text={templateName} />;
  };

  onSelectTemplate = (index, template) => {
    try {
      const templateBody = Utility.decodeHTML(
        template.cells[
          this.templateTableColumnCodeIdMap[COLUMN_CODE.TEMPLATE.HTML_DATA]
        ]
      );
      if (!templateBody?.length) return;

      this.toggleTemplateSelector(false);

      const range = this.quillEditor.getSelection(true);

      if (range.length) {
        const selectedText = this.quillEditor.getText(
          range.index,
          range.length
        );
        this.quillEditor.deleteText(range.index, selectedText.trimEnd().length);
      }

      this.quillEditor.clipboard.dangerouslyPasteHTML(
        range.index + 1,
        templateBody
      );
      this.quillEditor.setSelection(range.index + templateBody.length + 1);
    } catch (err) {
      console.log("Template insertion error", err?.message);
    }
  };

  onAddOrEditTemplate = (index = 0, template = {}) => {
    this.toggleTemplateSelector(false);
    showEmailTemplatePopup({
      module: this.props.tableName || TABLES.CONTACT,
      templateToEdit: template,
      needEmailPlaceholderTool: this.props.needEmailPlaceholderTool,
      additionalPlaceholders: this.props.additionalPlaceholders,
      handleTemplateInsertion: (templateData) =>
        this.onSelectTemplate(index, templateData)
    });
  };

  onDeleteTemplate = (index, template) => {
    this.toggleTemplateSelector(false);

    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => {
          Table.deleteRecord(
            template._id,
            TableManger.getTableId(TABLES.TEMPLATE)
          )
            .then(() => {
              store.dispatch(
                deleteRecord({
                  tableName: TABLES.TEMPLATE,
                  recIds: [template._id]
                })
              );
            })
            .catch(() => {});
        }
      }
    ];
    showAlert(
      "Delete Record?",
      "Deleting this record will delete it permanently you will not be able to restore it.",
      buttons
    );
  };

  getTemplateSelectorListPickerConfig = () => {
    let listPickerConfig = TableDataParser.getLinkedRecordColumnDropdownConfig(
      TABLES.TEMPLATE,
      TABLES.TEMPLATE
    );

    const module = (this.props.tableName || TABLES.CONTACT).toUpperCase();
    const templateTagId = TEMPLATE_MODULE_ID[module];

    listPickerConfig.searchApiConfig.getPayload = (searchValue) => {
      return TableManger.getFilterObject([
        {
          key: this.templateTableColumnCodeIdMap[COLUMN_CODE.TEMPLATE.NAME],
          value: searchValue,
          condition: FILTER_OPERATORS.CONTAINS
        },
        {
          key: this.templateTableColumnCodeIdMap[COLUMN_CODE.TEMPLATE.TAGS],
          value: templateTagId,
          condition: FILTER_OPERATORS.CONTAINS
        }
      ]);
    };

    listPickerConfig.searchApiConfig.dataParser = (response) => {
      return response?.data || [];
    };

    listPickerConfig = {
      ...listPickerConfig,
      className:
        "app-font fw-r text-dark-gray position-absolute z-index-6 border-m shadow-s",
      style: { left: 0, top: 0, minWidth: 200, width: "100%" },
      data: store.getState().records.data[TABLES.TEMPLATE]?.data || [],
      renderer: this.templateRowRenderer,
      canEdit: true,
      canDelete: true,
      onEdit: this.onAddOrEditTemplate,
      onDelete: this.onDeleteTemplate,
      onSelect: this.onSelectTemplate,
      onClose: () => this.toggleTemplateSelector(false),
      button: {
        title: "+ Add Template",
        className: "bg-button text-white",
        onClick: () => this.onAddOrEditTemplate()
      }
    };

    return listPickerConfig;
  };

  renderTemplateSelector = () => {
    return (
      <div className="position-relative">
        <DKIcon
          src={DKIcons.ic_arrow_down2}
          className="ic-s template-selector-button"
          onClick={() => this.toggleTemplateSelector(true)}
        />
        {this.state.needTemplateSelector && (
          <DKListPicker2 {...this.getTemplateSelectorListPickerConfig()} />
        )}
      </div>
    );
  };
}

export default EmailEditorQuill;
